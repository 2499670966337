import { Formik } from "formik";
import { useRef, useState } from "react";
import {Modal, Container, Row, Col, Form} from "react-bootstrap";
import { getUserInviteSchema, getBulkInviteColumnSchema } from "../../libs/shared/schemas";
import FirstNameField from "../forms/fields/FirstNameField";
import EmailField from "../forms/fields/EmailField";
import { useErrorContext } from "../../libs/contextLib";
import { getInviteSharingLink, inviteCandidateToJob, inviteFileToJob } from "../../api/lambda_api";
import PitchButton from "../buttons/PitchButton";
import InputWithCopy from "../forms/fields/InputWithCopy";
import { getFullUrl, useUser } from "../../libs/hooksLib";
import FormSubtitle from "../text/FormSubtitle";
import DataFileUploader from "../file/DataFileUploader";

export default function InviteSharingModal({callback, job}) {

    const shareModes = {
        EMAIL: 1,
        BULK: 2,
        LINK: 3
    };

    const [shareMode, setShareMode] = useState();
    const [page, setPage] = useState(0);
    const [sharingUrl, setSharingUrl] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {user} = useUser();
    const {setError} = useErrorContext();
    const emailFormRef = useRef();
    const [firstName, setFirstName] = useState();
    const columnSelectFormRef = useRef();
    const [bulkResults, setBulkResults] = useState();

    async function handleEmailFormSubmit(data){
        try{
            setIsLoading(true);
            setFirstName(data.firstName);
            const link = await inviteCandidateToJob(job, data.firstName, data.email);
            setSharingUrl(getFullUrl("links/invite/" + link.linkId));
            setPage(prev => prev + 1);
            setIsLoading(false);
        }catch(e){
            setError(e);
        }
        setIsLoading(false);
    }

    async function handleColumnSelectFormSubmit(data, filename, hasHeaderRow){
        try{
            setIsLoading(true);
            const results = await inviteFileToJob(job, "private/" + user.identityId + "/" + filename, hasHeaderRow, data.Firstname, data.Email);
            setBulkResults(results);
            setPage(prev => prev + 1);
            setIsLoading(false);
        }catch(e){
            setError(e);
        }
        setIsLoading(false);
    }

    async function getLink(){
        try{
            setIsLoading(true);
            const sharingLink = await getInviteSharingLink(job);
            const url = getFullUrl("links/invite/" + sharingLink.linkId);
            setSharingUrl(url);
            setIsLoading(false);
        }catch(e){
            setError(e);
        }
    }

    function renderChoice(){
        return (
            <Row>
                <Col xs={12}>
                    <FormSubtitle>How would you like to invite a candidate?</FormSubtitle>
                </Col>
                <Col xs={12} className="py-2">
                    <PitchButton block isLoading={isLoading} onClick={() => {setShareMode(shareModes.EMAIL); setPage(prev => prev + 1);}} variant="complement">By email</PitchButton>
                </Col>
                <Col xs={12} className="py-2">
                    <PitchButton block isLoading={isLoading} onClick={() => {setShareMode(shareModes.LINK); getLink(); setPage(prev => prev + 1);}} variant="primary">By link</PitchButton>
                </Col>
                <Col xs={12} className="py-2">
                    <PitchButton block isLoading={isLoading} onClick={() => {setShareMode(shareModes.BULK); setPage(prev => prev + 1);}} variant="success">In bulk (via upload)</PitchButton>
                </Col>
            </Row>
        )
    }

    function renderEmailInput(){
        return(
            <Container>
                <Row className="mb-3"><Col>Enter candidate's contact information:</Col></Row>
                <Formik 
                    innerRef={emailFormRef}
                    validationSchema={getUserInviteSchema()}
                    onSubmit={handleEmailFormSubmit}
                    initialValues={{
                        firstName: "",
                        email: ""
                    }}
                    >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <FirstNameField autoFocus={true} values={values} handleChange={handleChange} handleBlur={handleBlur} touched={touched} errors={errors} />
                        <EmailField autoFocus={false} values={values} handleChange={handleChange} handleBlur={handleBlur} touched={touched} errors={errors} />
                    </Form>
                    )}
                </Formik>
            </Container>
        );
    }

    function renderEmailUpload(){
        return(
            <DataFileUploader 
                promptText={(<span>Please upload a CSV containing at least <strong>2</strong> columns for <strong>first name</strong> and <strong>email address</strong></span>)}
                columnSelectors={['First name', 'Email']}
                ref={columnSelectFormRef}
                handleSubmit={handleColumnSelectFormSubmit}
                columnSelectorSchema={getBulkInviteColumnSchema()}
                fileSizeLimit={3}
                />
        )
    }

    function renderConfirmation(){
        return(
            <Container>
                <Row className="mb-3"><Col>Success! {firstName} will receive an email invite. Need an additional link?</Col></Row>
                <InputWithCopy text={sharingUrl} />
            </Container>
        )
    }

    function renderBulkConfirmation(){
        return(
            <Container>
                <Row className="mb-3"><Col>
                    {bulkResults.invitesSent > 1 && (
                        <>Success! <strong>{bulkResults.invitesSent}</strong> candidate{bulkResults.invitesSent > 1 ? 's' : null} will receive an email invite.</>
                    )}
                    {bulkResults.invitesSent === 0 && (
                        <>There's been a problem! No valid email addresses were found, please check the errors below.</>
                    )}
                </Col></Row>
                {bulkResults.invalidEmails.length > 0 && (
                    <Row className="mb-3"><Col>
                        However, there were some errors. <strong>{bulkResults.invalidEmails.length}</strong> email{bulkResults.invalidEmails.length > 1 ? 's' : null} was invalid.
                    </Col></Row>
                )}
                {bulkResults.duplicateEmails.length > 0 && (
                    <Row className="mb-3"><Col>
                        There were some duplicates. <strong>{bulkResults.duplicateEmails.length}</strong> email{bulkResults.duplicateEmails.length > 1 ? 's' : null} had duplicates.
                    </Col></Row>
                )}
            </Container>
        )
    }

    function renderShareLink(){
        return(
            <Container>
                <InputWithCopy text={sharingUrl} />
            </Container>
        )
    }

    function renderBody(mode, page){
        switch(mode){
            case shareModes.EMAIL:
                switch(page){
                    case 1:
                        return renderEmailInput();
                    case 2:
                        return renderConfirmation();
                    default:
                        return renderEmailInput();
                }
            case shareModes.BULK:
                switch(page){
                    case 1:
                        return renderEmailUpload();
                    case 2:
                        return renderBulkConfirmation();
                    default:
                        return renderEmailUpload();
                }
            case shareModes.LINK:
                switch(page){
                    case 1:
                        return renderShareLink();
                    default:
                        return renderShareLink();
                }
            default:
                return renderChoice();
        }
    }

    function onProgressClick(mode, page){
        switch(mode){
            case shareModes.EMAIL:
                switch(page){
                    case 1:
                        if(emailFormRef.current.isValid){
                            emailFormRef.current.handleSubmit();
                        }
                        break;
                    case 2:
                        callback();
                        break;
                    default:
                }
                break;
            case shareModes.BULK:
                switch(page){
                    case 1:
                        if(columnSelectFormRef.current && columnSelectFormRef.current.isValid){
                            columnSelectFormRef.current.handleSubmit();
                        }
                        break;
                    case 2:
                        callback();
                        break;
                    default:
                }
                break;
            case shareModes.LINK:
                switch(page){
                    case 1:
                        callback();
                        break;
                    default:
                }
                break;
            default:
        }
    }

    return (
        <>
            <Modal.Header className="container-secondary-2-2" closeButton onHide={callback}>
                <Modal.Title>Invite candidate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderBody(shareMode, page)}
            </Modal.Body>
            <Modal.Footer>
                    <PitchButton variant="secondary" onClick={() => callback()}>
                        Cancel
                    </PitchButton>
                {page !== 0 && (<PitchButton isLoading={isLoading} variant={"primary"} onClick={() => onProgressClick(shareMode, page)}>
                    {page === 1 && (shareMode === shareModes.EMAIL || shareMode === shareModes.BULK) ? "Submit" : "Ok"}
                </PitchButton>)}
            </Modal.Footer>
        </>
    );
}