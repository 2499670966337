import React from "react";
import InviteListItem from "../../components/invites/InviteListItem";
import {loadInvites} from "../../api/lambda_api";
import { Col, Row } from "react-bootstrap";
import FeedContainer from "../../components/layout/FeedContainer";
import { statusFilterTypes, viewTypes } from "../../libs/shared/enums";
import InviteActionsBar from "../../components/invites/InviteActionsBar";
import InviteLinkContainer from "../../components/invites/InviteLinkContainer";
import InviteFeedItem from "../../components/invites/InviteFeedItem";
import { useUser } from "../../libs/hooksLib";

export default function HomeInvites() {

	const {user} = useUser();

	const loadInvitesCallback = async(filter, lastEvaluatedKey) => {
		const now = new Date();
		const startRange = filter.status === statusFilterTypes.ACTIVE ? now.getTime() : null; 
		const endRange = filter.status === statusFilterTypes.CLOSED ? now.getTime() : null;
		const inviteSearchResult = await loadInvites(startRange, endRange, lastEvaluatedKey);
		return inviteSearchResult;
	}

  function renderInvitesList() {
	  return (
	    <>
			<FeedContainer
				itemIdFunction={(invite) => invite.inviteId}
				initialFilter={{view: viewTypes.FEED, status: statusFilterTypes.ALL}}
				ItemListComponent={InviteListItem} 
				ItemFeedComponent={InviteFeedItem} 
				loadItemsFunction={loadInvitesCallback}
				viewOptions={[viewTypes.FEED, viewTypes.LIST]}
				filterOptionsMeta={{[statusFilterTypes.ALL]: user.getCountByKeyFilter(key => key.startsWith("Invite"))}}
				ItemToolbarComponent={InviteActionsBar}
				ItemLinkComponent={InviteLinkContainer} />
	    </>
	  );
	}

	function renderInvites() {
		return (
			<>
				<Row className="pb-3 mt-4 mb-3 border-bottom ps-3">
					<Col md={6} className="text-left"><h2>Your invites</h2></Col>
				</Row>
				<Row>
					<Col md={12}>
						{renderInvitesList()}
					</Col>
				</Row>
			</>
		);
	  }

  return (
    <div className="Home">
      {renderInvites()}
    </div>
  );
}