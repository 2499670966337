import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import queryString from "query-string";
import PitchRoute from "./PitchRoute";

export default function UnauthenticatedRoute({ children, showLoginNav = true, title = null, ...rest }) {
  const { isAuthenticated, setShowLoginNav } = useAppContext();
  const location = useLocation();
  const redirect = queryString.parse(location.search).redirect;

  useEffect(() => {
    setShowLoginNav(showLoginNav);
  }, [setShowLoginNav, showLoginNav]);

  return (
    <PitchRoute title={title} {...rest}>
      {!isAuthenticated ? (
        children
      ) : (
        <Redirect to={redirect === "" || redirect === null || redirect === undefined ? "/" : redirect} />
      )}
    </PitchRoute>
  );
}