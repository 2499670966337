import { useEffect, useRef, useState } from "react";
import { useErrorContext } from "../../libs/contextLib";
import PitchButton from "../buttons/PitchButton";
import {Container, ProgressBar} from "react-bootstrap";
import { PitchUserError } from "../../libs/shared/exceptions";
import Jumbotron from "../layout/Jumbotron";

export const fileTypes = {
    CSV: "text/csv",
    PITCH: "video/mp4,video/x-m4v,video/*"
}

export default function FileLandingArea({initialObject = null, isLoading = false, multiFile=false, type = fileTypes.CSV, fileSizeLimit = null, buttonUploadText = "Upload pitch", buttonDeleteText = "Delete pitch", showProgressBar = true, uploadFnc, deleteFnc, callback = null}){


    const [isUploading, setIsUploading] = useState(isLoading);
    const [result, setResult] = useState(!multiFile ? initialObject : null);
    const [progress, setProgress] = useState();
    const inputFile = useRef(null);
    const {setError} = useErrorContext();

    useEffect(() => setIsUploading(isLoading), [isLoading]);

    async function handleFileUpload(event) {
        if(!event.target.files[0]) return; 
        try {
            setIsUploading(true);
            const file = event.target.files[0];
            if(fileSizeLimit && (file.size / 1000000) > fileSizeLimit) throw new PitchUserError(`File size is too large. Maximum size is ${fileSizeLimit}Mb, file size was ${(file.size / 1000000)}Mb`);
            const res = await uploadFnc(file, setProgress);
            if(!multiFile) setResult(res);
            if(callback) callback(res);
            setIsUploading(false);
            setProgress(0);
        }catch(e){
            setError(e);
        }finally{
            event.target.value = null;
            setIsUploading(false);
        }
    }

    async function handleDelete(){
        if(!multiFile){
            try{
                setIsUploading(true);
                const res = await deleteFnc(result, setProgress);
                setResult(res);
                if(callback) callback(res);
            }catch(e){
                setError(e);
            }finally{
                setIsUploading(false);
            }
        }
    }

    const onUploadButtonClick = () => {
        inputFile.current.click();
    };

    function renderAttachment(){
        return(
            <Container className="d-flex justify-content-center">
                <PitchButton className="btn-lg" variant="complement"
                    isLoading={isUploading}
                    onClick={handleDelete}>{buttonDeleteText}</PitchButton>
            </Container>
        );
    }

    function renderUploader(){
        return(
            <>
                <input type="file" className="hidden"
                    ref={inputFile}
                    onChange={handleFileUpload} 
                    accept={type} />
                <Container className="d-flex justify-content-center">
                    <PitchButton className="btn btn-lg" variant="success"
                        isLoading={isUploading}
                        onClick={onUploadButtonClick}>{buttonUploadText}</PitchButton>
                </Container>
            </>
        )
    }

    return(
        <>
            <Jumbotron>
                {result ? renderAttachment() : renderUploader()}
            </Jumbotron>
            {showProgressBar && <Container className="col-12">
                {isUploading ? <ProgressBar striped variant="complement" now={progress} label={progress + "%"}/> : null }
            </Container>}
        </>
    );
}