import Select from "react-select";

export default function ColumnSelectField({
    field: { name, value },
    form: {
      setFieldValue, setFieldTouched
    },
    isInvalid,
    className,
    options
}){

    const customStyles = {
        control: (base, state) => ({
          ...base,
          // state.isFocused can display different borderColor if you need it
          borderColor: state.isFocused ?
            '#ddd' : isInvalid ? '#dc3545' : '#ddd',
          // overwrittes hover style
          '&:hover': {
            borderColor: state.isFocused ?
              '#ddd' : isInvalid ? '#dc3545' : '#ddd'
          }
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999
        })
      }

    const changeHandler = value => {
        setFieldValue(name, value.value);
        setFieldTouched(name, true, false);
    }

    return <Select 
              styles={customStyles} 
              className={isInvalid ? `is-invalid ${className}` : className} 
              options={options}
              value={value || value === 0 ? {label: value, value} : null}
              placeholder={"Select column"}
              onChange={changeHandler} 
              menuPortalTarget={document.body}
              />

}