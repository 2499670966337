import { Form } from "react-bootstrap";

export default function SwitchField({id, checked, label, handleChange, handleBlur, touched, error, disabled = false}){
    return(
        <Form.Group controlId={id} size="lg"className="my-3" >
            <Form.Check
                type="switch"
                className="form-switch-md"
            >
                <Form.Check.Input
                    id={id}
                    name={id}
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched && !error}
                    isInvalid={touched && error}
                />
                <Form.Check.Label className="custom-control-label">{label}</Form.Check.Label>
            </Form.Check>
        </Form.Group>
    );
}