import { Job } from "../../libs/shared/model";
import { Badge} from "react-bootstrap";
import { Countdown } from "../text/Countdown";

export default function JobListItem({
    item,
    isShared = false,
    action,
    ...props
  }) {

    return(
        <>
            {isShared && (<><span className="text-success">
                {item.isRepresented ? item.recruitingOrganisation : item.organisationName}
            </span>
            <br/></>)}
            {item.recruitingOrganisation && (<><span className="text-success">
                {item.organisationName}
            </span>
            <br/></>)}
            <span className={item.jobTitle ? "fw-bold" : "text-muted"}>
                {item.jobTitle ? item.jobTitle : "Untitled job"}
            </span>
            <span className={item.managerFirstName ? "" : "text-muted"}>
                {item.managerFirstName ? ' - ' + (item.managerLastName ? item.managerFirstName + " " + item.managerLastName : item.managerFirstName) : ""}
            </span>
            <br/>
            {item.closingDate && (<><span className="text-fineprint">
                Closing: <Countdown deadline={item.closingDate} periods={['years', 'months', 'days', 'hours', 'minutes']}/>
            </span><br/></>)}
            <Badge pill bg={Job.jobStatusTypesStyles[item.status]}>{Job.jobStatusTypesNames[item.status]}</Badge>
        </>
    )

}