import Badge from "react-bootstrap/Badge";
import { Invite } from "../../libs/shared/model";
import { Countdown } from "../text/Countdown";

export default function InviteListItem({
    item,
    ...props
  }) {

    return(
        <>
            <span className={item.jobTitle ? "fw-bold" : "text-muted"}>
                {item.job.jobTitle ? item.job.jobTitle : "Untitled job"}
            </span>
            <span className={item.managerFirstName ? "" : "text-muted"}>
                {item.job.managerFirstName ? ' - ' + (item.job.managerLastName ? item.job.managerFirstName + " " + item.job.managerLastName : item.job.managerFirstName) : ""}
            </span>
            <br/>
            {item.deadline && (<>
                {item.hasDeadlinePassed() ? 
                    (
                        <span className="text-fineprint">Closed: <Countdown deadline={item.deadline} periods={['years', 'months', 'days', 'hours', 'minutes']}/></span>
                    ) : (
                        <span className="text-fineprint">Closing: <Countdown deadline={item.deadline} periods={['years', 'months', 'days', 'hours', 'minutes']}/></span>
                    )}
            <br/></>)}
            <Badge pill bg={Invite.inviteStatusTypesStyles[item.status]}>{Invite.inviteStatusTypesNames[item.status]}</Badge>
        </>
    )

}