import { Container } from "react-bootstrap";
import FormSubtitle from "../text/FormSubtitle";
import FormTitle from "../text/FormTitle";

export default function CricketsImage({text}){
    return (
        <Container className="text-center">
            {text && (<FormTitle>{text}</FormTitle>)}
            <FormSubtitle>Nothing new, check back later</FormSubtitle>
        </Container>
    );
}