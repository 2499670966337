import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { isOrgAccountActive } from "../../api/localstorage_api";
import { useAppContext } from "../../libs/contextLib";
import { isOrganisationSignUpComplete, useOrganisation, useUser } from "../../libs/hooksLib";
import PlaceholderPage from "../layout/PlaceholderPage";
import PitchRoute from "./PitchRoute";

export default function OrgAuthenticatedRoute({ children, showLoginNav = true, title = null, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const { setShowLoginNav } = useAppContext();
  const {user} = useUser();
  const {organisation} = useOrganisation();

  useEffect(() => {
    setShowLoginNav(showLoginNav);
  });

  return (
    <PitchRoute title={title} {...rest}>
      {isAuthenticated ? (
          isOrgAccountActive() ? (
            isOrganisationSignUpComplete(isAuthenticated, user, organisation) ? (
              children
            ) : (
              <Redirect to={
                `/org_signup?redirect=${pathname}${search}`
              } />
            )
          ) : (
            <PlaceholderPage>
                <h3>Sorry! You do not have permission to view this page.</h3>
            </PlaceholderPage>
          )
      ) : (
        <Redirect to={
          `/org_signup?redirect=${pathname}${search}`
        } />
      )}
    </PitchRoute>
  );
}