import HomeOrganisation from "./HomeOrganisation";
import HomeInvites from "./HomeInvites";
import { isOrgAccountActive } from "../../api/localstorage_api";

export default function Home(){

    return(
        <>
            {isOrgAccountActive() ? (
                <HomeOrganisation />
            ) : (
                <HomeInvites />
            )}
        </>
    );
}