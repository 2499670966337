import { Field } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import ColumnSelectField from "./ColumnSelectField";

export default function ColumnSelectFieldGroup({value, id, label, options, onBlur, touched, error}){
    return(
        <Form.Group as={Row} className="my-3" controlId={id} size="lg">
            <Form.Label column sm={3} className="pe-1">{label}</Form.Label>
            <Col sm={9} className="ps-1">
                <Field
                    name={id}
                    component={ColumnSelectField}
                    value={value}
                    onBlur={onBlur}
                    isValid={touched && !error}
                    isInvalid={touched && error}
                    options={options}
                />
                <Form.Control.Feedback className="invalid-feedback">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}