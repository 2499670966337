import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { isOrgAccountActive } from "../../api/localstorage_api";
import { useAppContext } from "../../libs/contextLib";
import { isOrganisationSignUpComplete, isUserSignUpComplete, useOrganisation, useUser } from "../../libs/hooksLib";
import PitchRoute from "./PitchRoute";

export default function AuthenticatedRoute({ children, showLoginNav = true, title = null, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const { setShowLoginNav } = useAppContext();
  const {user} = useUser();
  const {organisation} = useOrganisation();

  useEffect(() => {
    setShowLoginNav(showLoginNav);
  });

  return (
    <PitchRoute title={title} {...rest}>
      {isAuthenticated ? (
          isOrgAccountActive() ? (
            isOrganisationSignUpComplete(isAuthenticated, user, organisation) ? (
              children
            ) : (
              <Redirect to={
                `/org_signup?redirect=${pathname}${search}`
              } />
            )
          ) : (
            isUserSignUpComplete(isAuthenticated, user) ? (
              children
            ) : (
              <Redirect to={
                `/signup?redirect=${pathname}${search}`
              } />
            )
          )
      ) : (
        <Redirect to={
          `/signup?redirect=${pathname}${search}`
        } />
      )}
    </PitchRoute>
  );
}