import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import queryString from "query-string";
import { isUserSignUpComplete, useOrganisation, useUser } from "../../libs/hooksLib";
import PitchRoute from "./PitchRoute";

export default function SignupRoute({ children, showLoginNav = true, title = null, ...rest }) {
  const { isAuthenticated } = useAppContext();
  const { setShowLoginNav } = useAppContext();
  const {user} = useUser();
  const {organisation} = useOrganisation();
  const location = useLocation();
  const redirect = queryString.parse(location.search).redirect;

  useEffect(() => {
    setShowLoginNav(showLoginNav);
  });

  return (
    <PitchRoute title={title} {...rest}>
      {!isUserSignUpComplete(isAuthenticated, user, organisation) ? (
        children
      ) : (
        <Redirect to={redirect === "" || redirect === null || redirect === undefined ? "/" : redirect} />
      )}
    </PitchRoute>
  );
}