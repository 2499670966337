import { useEffect } from "react";
import { Route } from "react-router";

export default function PitchRoute({title, children, ...rest}){

    useEffect(() => {
        document.title = (title && title !== "title") ? title + " | Pitchli" : "Pitchli";
    }, [title]);

    return (
        <Route {...rest}>
            {children}
        </Route>
    )
}