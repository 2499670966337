import { useRef } from "react";
import { Container, Image, Row } from "react-bootstrap";
import logger from "../../libs/errorLib";
import { useCalculatedDimensions } from "../../libs/hooksLib";

export default function ResponsiveImage({originalHeight, originalWidth, url, overlay, visible = true, className, containerClassName, onError}){

    const componentRef = useRef();
    const {height, width} = useCalculatedDimensions(componentRef, originalHeight, originalWidth, visible);

    return(
        <Container ref={componentRef} className={`${(containerClassName ? containerClassName : "") + "text-center p-0 bg-white"}`}>
            <Image src={url}
                height={height ? height + "px" : "auto"}
                width={width ? width + "px" : "auto"}
                onError={(e) => {
                    if(onError) onError(e);
                    logger.debug("Error loading image");
                }}
                className={className}
            />
            {overlay && (
                <Row style={{width: '100%', height: height + 'px', position: 'absolute', top: '0'}} className="text-center align-items-center p-0 m-0">
                    {overlay}
                </Row>
            )}
        </Container>
    )
}