import { Col, Container, Modal, Row } from "react-bootstrap";
import PitchButton from "../buttons/PitchButton";

export default function DownloadModal({callback, cancelCallback, downloadLink}) {
    function renderContents(){
        return (
            <Container>
                {!downloadLink && (<Row className="mb-3">
                    <Col>Preparing download...</Col>
                </Row>)}
                <Row>
                    <Col className="d-flex justify-content-center">
                        <PitchButton variant="complement" href={downloadLink} isLoading={!downloadLink} disabledTooltip={"Download being prepared"} disabled={!downloadLink}>Download</PitchButton>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <>
            <Modal.Header className="container-secondary-2-2" closeButton>
                <Modal.Title>Download</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderContents()}
            </Modal.Body>
            <Modal.Footer>
                <PitchButton variant="secondary" onClick={() => callback()}>
                    Ok
                </PitchButton>
            </Modal.Footer>
        </>
    );
}