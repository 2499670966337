import { Container, Button } from "react-bootstrap";
import { ChevronLeft } from "react-bootstrap-icons";
import { useHistory } from "react-router";

export default function BackButton({path = null, text = null}){

    const history = useHistory();

    return(
        <Container className="mb-3 pl-0">
            <Button onClick={() => path ? history.push(path) : history.goBack()} variant="link"><ChevronLeft/> {text ? text: "Back"}</Button>
        </Container>
    );
}