import { Col, Form, Row } from "react-bootstrap";

export default function FirstNameField({values, handleChange, handleBlur, touched, errors, autoFocus = false}){
    return(
        <Form.Group as={Row} controlId="firstName" className="my-3" size="lg">
            <Form.Label column sm={3} className="pe-1">First name</Form.Label>
            <Col sm={9} className="ps-1">
                <Form.Control
                    autoFocus={autoFocus}
                    type="text"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.firstName && !errors.firstName}
                    isInvalid={touched.firstName && errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                {errors.firstName}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    )
}