import { ButtonToolbar } from "react-bootstrap"
import { ClockHistory, EyeFill, GraphUp, Pencil, PersonPlusFill, PlayFill, ShareFill, TrashFill } from "react-bootstrap-icons"
import { useHistory } from "react-router";
import { deleteJob, updateJob } from "../../api/lambda_api";
import { useErrorContext, useInfoContext } from "../../libs/contextLib";
import { useOrganisation } from "../../libs/hooksLib";
import { Job, Permission } from "../../libs/shared/model";
import PitchButton from "../buttons/PitchButton";
import InviteSharingModal from "../sharing/InviteSharingModal";
import JobSharingModal from "../sharing/JobSharingModal";

export default function JobActionBar({item, isShared = false, removeItemFunction, isOrgSharingAllowed = false}){

    const {reloadOrganisation} = useOrganisation();
    const {setUserMessageModal, setUserMessage} = useInfoContext();
    const {setError} = useErrorContext();
    const history = useHistory();

    async function goToAnalytics(){
        history.push(`/organisations/${item.orgId}/jobs/${item.jobId}/analytics`);
    }

    async function goToEdit(){
        history.push(`/organisations/${item.orgId}/jobs/${item.jobId}/edit?step=0`);
    }

    async function expireJobPosting(){
        setUserMessage({
            title: "Expire job",
            body: "Close job and stop further applications?",
            buttonText: "Ok",
            cancelButtonText: "Cancel",
            callback: async() => {
              try {
                  item.closingDate = Date.now();
                  item.status = Job.jobStatusTypes.EXPIRED;
                    await updateJob(item);
                    reloadOrganisation();

              } catch (e) {
                setError(e);
              }
            }
        });
    }

    async function goToView(){
        history.push(`/organisations/${item.orgId}/jobs/${item.jobId}/view`);
    }

    async function goToReview(){
        if(!isShared){
            history.push(`/organisations/${item.orgId}/jobs/${item.jobId}/review`);
        }else{
            history.push(`/organisations/${item.orgId}/jobs/${item.jobId}/shortlist`);
        }
    }

    async function deleteJobPosting(){
        setUserMessage({
            title: "Delete job",
            body: "Deleting a job cannot be undone, are you sure?",
            buttonText: "Ok",
            cancelButtonText: "Cancel",
            callback: async() => {
              try {
                await deleteJob(item);
                removeItemFunction(item.jobId);
              } catch (e) {
                setError(e);
              }
            }
        });
    }

    function openInviteOptions(){
        setUserMessageModal({component: InviteSharingModal, props: {job: item}});
    }

    function openSharingOptions(){
        setUserMessageModal({component: JobSharingModal, props: {job: item, allowOrganisation: !isShared && isOrgSharingAllowed}});
    }

    return(
        <ButtonToolbar>
            {item.status >= Job.jobStatusTypes.POSTED && (
                <PitchButton 
                    tooltip="View job analytics" 
                    className="btn-circle p-0 m-2" 
                    size="md" 
                    variant="outline-secondary" 
                    onClick={goToAnalytics}>
                        <GraphUp size={item.userPermission.permission < Permission.permissionTypes.VIEW_OWNER ? 14 : 18}/>
                </PitchButton>
            )}
            {item.status >= Job.jobStatusTypes.POSTED && (
                <PitchButton tooltip="View job" className="btn-circle p-0 m-2" size="md" variant="outline-secondary" onClick={goToView}><EyeFill size={18}/></PitchButton>
            )}
            {!isShared && (
                <PitchButton 
                    className="btn-circle p-0 m-2" 
                    tooltip="Edit job"
                    disabled={item.userPermission.permission < Permission.permissionTypes.UPDATE} 
                    size="md" 
                    variant="outline-secondary" 
                    onClick={goToEdit}>
                        <Pencil size={item.userPermission.permission < Permission.permissionTypes.UPDATE ? 14 : 18}/>
                </PitchButton>
            )}
            {!isShared && item.status === Job.jobStatusTypes.POSTED && (
                <PitchButton 
                    className="btn-circle p-0 m-2" 
                    tooltip="Expire job"
                    disabled={item.userPermission.permission < Permission.permissionTypes.UPDATE} 
                    size="md" 
                    variant="outline-secondary" 
                    onClick={expireJobPosting}>
                        <ClockHistory size={item.userPermission.permission < Permission.permissionTypes.UPDATE ? 14 : 18}/>
                </PitchButton>
            )}
            {item.status >= Job.jobStatusTypes.POSTED && (
                <PitchButton 
                    className="btn-circle p-0 m-2" 
                    tooltip="Review candidates"
                    size="md" variant="outline-secondary" 
                    onClick={goToReview}>
                        <PlayFill size={18}/>
                </PitchButton>
            )}
            {item.status === Job.jobStatusTypes.POSTED && !isShared && (
                <PitchButton 
                    className="btn-circle p-0 m-2" 
                    tooltip="Invite candidates"
                    disabled={item.userPermission.permission < Permission.permissionTypes.SHARE} 
                    size="md" 
                    variant="outline-secondary" 
                    onClick={openInviteOptions}>
                        <ShareFill size={item.userPermission.permission < Permission.permissionTypes.SHARE ? 14 : 18}/>
                </PitchButton>
            )}
            {item.status === Job.jobStatusTypes.POSTED && (
                <PitchButton 
                    className="btn-circle p-0 m-2" 
                    tooltip="Add other users"
                    disabled={item.userPermission.permission < Permission.permissionTypes.DELETE} 
                    size="md" 
                    variant="outline-secondary" 
                    onClick={openSharingOptions}>
                        <PersonPlusFill size={item.userPermission.permission < Permission.permissionTypes.DELETE ? 14 : 18}/>
                </PitchButton>
            )}
            {item.status < Job.jobStatusTypes.POSTED && (
                <PitchButton 
                    className="btn-circle p-0 m-2" 
                    tooltip="Delete job"
                    disabled={item.userPermission.permission < Permission.permissionTypes.DELETE} 
                    size="md" 
                    variant="outline-secondary" 
                    onClick={deleteJobPosting}>
                        <TrashFill size={item.userPermission.permission < Permission.permissionTypes.DELETE ? 14 : 18}/>
                </PitchButton>
            )}
        </ButtonToolbar>
    )
}