import {DateTime} from "luxon";
import { useEffect, useState } from "react";
import { formatDateTime, formatTimeDifference } from "../../libs/utilities";
import { useInterval } from "../../libs/hooksLib";

export function Countdown({deadline, periods}){
    const [deadlinePassed, setDeadlinePassed] = useState(false);
    const [timeDifference, setTimeDifference] = useState();

    useEffect(() => {
        setTimeDifference(null);
    },[deadline]);

    useInterval(() => {
        const now = DateTime.now();
        const then = DateTime.fromMillis(deadline);
        //['years', 'months', 'days', 'hours', 'minutes']
        if(now > then) {
            setDeadlinePassed(true);
        }

        setTimeDifference(then.diff(now, periods).values);
    }, !deadlinePassed ? !timeDifference ? 0 : 60000 : null);

    return(
        <>
            {timeDifference && !deadlinePassed ? formatTimeDifference(timeDifference, {periods}) : formatDateTime(deadline)}
        </>
    )
}