import { Col, Container, ListGroup, Row } from "react-bootstrap";

export default function FeedListItem({item, LinkComponent, listComponent, toolbarComponent}){
    return(
        <ListGroup.Item className="py-2 px-3">
            <Container>
                <Row>
                    {renderListBar(LinkComponent, item, listComponent, toolbarComponent)}
                </Row>
            </Container>
        </ListGroup.Item>
    )
}

export function renderListBar(LinkComponent, item, listComponent, toolbarComponent){
    return (
        <>
            {LinkComponent ? (<LinkComponent item={item}>
                <Col lg={toolbarComponent ? 6 : 12} className="p-2">
                    {listComponent}
                </Col>
            </LinkComponent>
            ) : (
                <Col lg={toolbarComponent ? 6 : 12} className="p-2">
                    {listComponent}
                </Col>
            )}
            {toolbarComponent && (<Col lg={6} className="d-flex justify-content-end align-items-center">
                {toolbarComponent}
            </Col>)}
        </>
    )
}