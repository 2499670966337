import { useEffect, useRef, useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import { Pitch } from "../../libs/shared/model";
import PitchPlayer from "./PitchPlayer";

export default function PitchCarousel({
    user = null,
    orgId = null,
    logView = false,
    viewType = 'invite',
    pitches, 
    disabled = false,
    onStarted = null, 
    onPitchStart = null, 
    onProgress = null, 
    onPitchComplete = null, 
    onAllComplete = null}){

    const containerRef = useRef();
    const [activeIndex, setActiveIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);

    function moveOnIndex(){
        setProgress(0);
        if(onPitchComplete) onPitchComplete(activeIndex);
        if(activeIndex === pitches.length -1){
            //Finished carousel
            setIsPlaying(false);
            setActiveIndex(0);
            if(onAllComplete) onAllComplete();
        }else{
            setActiveIndex(activeIndex + 1);
        }
    }

    //If the last pitch has been deleted we need to reset the active index
    useEffect(() => {
        if(activeIndex >= pitches.length) setActiveIndex(pitches.length -1);
    },[activeIndex, pitches.length])

    return(
        <Container ref={containerRef} className="bg-dark p-0 m-0">
            <Carousel
                className="video-carousel"
                style={{"--progress": progress}}
                activeIndex={activeIndex}
                interval={null}
                onSelect={(eventKey) => {setActiveIndex(eventKey); setProgress(0);}}
                nextIcon={<span aria-hidden="true" className="carousel-control-next-icon carousel-control-next-icon-secondary" />}
                prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon carousel-control-prev-icon-secondary" />}
                >
                {pitches.map((pitch, index) => (
                    <Carousel.Item
                        key={index}>
                        <PitchPlayer
                            user={user}
                            orgId={orgId}
                            logView={logView}
                            viewType={viewType}
                            blur={disabled}
                            pitchKey={pitch.getKey()}
                            level={Pitch.privacyLevelNames[pitch.privacyLevel]}
                            mediaUri={pitch.mediaUri}
                            encodedAvailable={pitch.encoded}
                            identityId={pitch.identityId}
                            visible={index===activeIndex}
                            playing={index===activeIndex && isPlaying}
                            showThumbnailInitially={index!==activeIndex || !isPlaying}
                            originalHeight={pitch.height}
                            originalWidth={pitch.width}
                            onEnded={() => moveOnIndex()}
                            onProgressFrequent={(progress) => setProgress(progress.played)}
                            onProgressInFrequent={(progress) => onProgress ? onProgress(index, progress) : null}
                            onClickPreview={() => setIsPlaying(true)}
                            onStarted={() => {
                                if(onStarted && !isPlaying) onStarted();
                                if(onPitchStart) onPitchStart(index);
                                setIsPlaying(true);
                            }}
                            />
                        {pitch.hasName() && (<Carousel.Caption className="no-pointer-events">
                            <p>{pitch.name}</p>
                        </Carousel.Caption>)}
                    </Carousel.Item>
                ))}
            </Carousel>
        </Container>
    )
}

