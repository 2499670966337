import React, {useEffect, useRef, useState} from "react";
import {useCalculatedDimensions, useInterval} from "../libs/hooksLib";
import { useErrorContext } from "../libs/contextLib";
import { PitchError } from "../libs/shared/exceptions";
import { Container } from "react-bootstrap";
import PitchButton from "./buttons/PitchButton";
import { VolumeMuteFill, VolumeUpFill } from "react-bootstrap-icons";
import Pitchli from "./icons/Pitchli";

const VideoPlayer = React.forwardRef(({
        url,
        originalHeight = 1080,
        originalWidth = 1920,
        playing = null,
        visible = true,
        autoPlay = false,
        loop = true,
        controls = true,
        mute = true,
        playsinline = true,
        containerClassName,
        className,
        light,
        onStart,
        onEnded,
        onProgressTuple1,
        onProgressTuple2,
        onSeek
    }, ref) => {

    const componentRef = useRef();
    const {height, width, maxHeight, maxWidth} = useCalculatedDimensions(componentRef, originalHeight, originalWidth, visible);
    const {setError} = useErrorContext();
    const [playbackStarted, setPlaybackStarted] = useState();
    const [isPlaying, setIsPlaying] = useState(playing != null ? playing : autoPlay);
    const [muted, setMuted] = useState(autoPlay ? false : mute);

    function onErrorHandler(e){
        if(e.name !== "NotAllowedError") setError(new PitchError("Failed to load or play video: " + e.message, e));
    }

    function handleClick(event){
        if(ref && ref.current){
            if(isPlaying){
                ref.current.pause();
            }else{
                ref.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    }

    function handleOnPlay(e){
        if(!playbackStarted){
            if(onStart) onStart(e);
            setPlaybackStarted(true);
        }
    }

    function handleOnEnded(e){
        setIsPlaying(false);
        setPlaybackStarted(false);
        onEnded(e);
    }

    useEffect(() => {
        if(ref && ref.current){
            if(!playing){
                ref.current.pause();
                setPlaybackStarted(false);
            }else{
                ref.current.play();
            }
        }
        setIsPlaying(playing);
    },[playing, ref]);

    useInterval(() => {
        if(ref && ref.current){
            onProgressTuple1.onProgress({
                played: Number.isFinite(ref.current.duration) ? (ref.current.currentTime / ref.current.duration) : 0,
                playedSeconds: ref.current.currentTime
            });
        }
    }, isPlaying && onProgressTuple1 ? onProgressTuple1.progressInterval : null);

    useInterval(() => {
        if(ref && ref.current){
            onProgressTuple2.onProgress({
                played: Number.isFinite(ref.current.duration) ? (ref.current.currentTime / ref.current.duration) : 0,
                playedSeconds: ref.current.currentTime
            });
        }
    }, isPlaying && onProgressTuple2 ? onProgressTuple2.progressInterval : null);

    return(
        <Container ref={componentRef} className={containerClassName ? `p-0 ${containerClassName}` : "p-0"} style={{maxWidth: maxWidth, maxHeight: maxHeight}}>
            <video
                className={className}
                ref={ref}
                autoPlay={playing != null ? playing : autoPlay}
                controls={controls}
                muted={muted}
                height={height + "px"}
                width={width + "px"}
                poster={light}
                loop={loop}
                onError={onErrorHandler}
                playsInline={playsinline}
                onClick={handleClick}
                onPlay={handleOnPlay}
                onEnded={handleOnEnded}
                onSeeked={onSeek}
            >
                <source src={url}/>
            </video>
            {!controls && <PitchButton onClick={() => setMuted(!muted)} className="btn-circle p-0" size="sm" variant="secondary" style={{position: "absolute", bottom: 20, right: 20, zIndex: 10}}>
                {muted ? (
                    <VolumeMuteFill size={18} />
                ) : (
                    <VolumeUpFill size={18}/>
                )}
            </PitchButton>}
            {!isPlaying && !controls && (<PitchButton onClick={handleClick} className="btn-circle p-0" size="xl" variant="light" style={{position: "absolute", top: "50%", left: "50%", marginTop: -35, marginLeft: -35, zIndex: 10}}>
                <Pitchli size={40} />
            </PitchButton>)}
        </Container>
    );
});

export default VideoPlayer;