import FormSubtitle from "../text/FormSubtitle";
import FormTitle from "../text/FormTitle";
import InviteCard from "./InviteCard";
import PitchPlayer from "../pitches/PitchPlayer";
import MultiPaneContainer from "../layout/MultiPaneContainer";
import { useCallback, useState } from "react";
import { useLogView, useOrganisation, useUser } from "../../libs/hooksLib";
import PitchCarousel from "../pitches/PitchCarousel";

export default function InviteCmp({invite, job, pitches, actionComponent}){

    const {user} = useUser();
    const {organisation} = useOrganisation();
    const jobView = useLogView(user, job.getKey(), organisation ? organisation.orgId : null);
    const [pitchSecondsPlayed, setPitchSecondsPlayed] = useState(0);

    const onPitchStart = useCallback(() => {
        if(jobView.view) jobView.incrementMetrics({pitch_started_invite: 1});
        setPitchSecondsPlayed(0);
    },[jobView]);

    const onProgress = useCallback((index, progress) => {
        if(Math.round(progress.playedSeconds - pitchSecondsPlayed) > 0){
            let timeIncrement = Math.round(progress.playedSeconds - pitchSecondsPlayed)
            if(jobView.view) jobView.incrementMetrics({pitch_secondsPlayed_invite: timeIncrement});
        }
        setPitchSecondsPlayed(progress.playedSeconds);
    },[jobView, pitchSecondsPlayed]);

    const onPitchComplete = useCallback((index) => {
        if(jobView.view) jobView.incrementMetrics({pitch_completed_invite: 1});
        setPitchSecondsPlayed(0);
    },[jobView]);

    const onAllComplete = useCallback(() => {
        if(jobView.view) jobView.incrementMetrics({pitch_all_completed_invite: 1});
    },[jobView])

    function getBottomLeftComponent(){
        return(
            <>
                <FormSubtitle className="pb-3">{job.isRepresented && (<><span className="text-complement">{job.recruitingOrganisation}</span><span> representing </span></>)}<span className="text-secondary-2">{job.organisationName}</span> would like you to pitch for:</FormSubtitle>
                <InviteCard
                    jobTitle={job.jobTitle}
                    location={job.location}
                    createdDate={invite.createdDate}
                    closingDate={invite.closingDate}
                    actionComponent={actionComponent}
                />
            </>
        )
    }

    function getBottomRightComponent(){
        return(
            <>
                <FormSubtitle>Hear from <span className="text-prime">{job.managerFirstName}</span> as to why you should work for <span className="text-secondary-2">{job.organisationName}</span></FormSubtitle>
                {pitches ? (
                    <PitchCarousel
                        user={user}
                        orgId={organisation?.orgId}
                        logView={!organisation || job.orgId !== organisation.orgId}
                        viewType={'invite'}
                        pitches={pitches}
                        onPitchStart={onPitchStart}
                        onProgress={onProgress}
                        onPitchComplete={onPitchComplete}
                        onAllComplete={onAllComplete}
                    />
                    /* <PitchPlayer 
                        level={Pitch.privacyLevelNames[pitch.privacyLevel]} 
                        mediaUri={pitch.mediaUri} 
                        encodedAvailable={pitch.encoded} 
                        identityId={pitch.identityId} 
                        originalHeight={pitch.height} 
                        originalWidth={pitch.width}
                        onStarted={onStart}
                        onProgress={onProgress}
                        onEnded={onEnd}
                        /> */
                ) : (
                    <PitchPlayer notStartedPlaceholder />
                )}
            </>
        )
    }

    function getMoreDetailsComponent(){
        return(
            <>
                <FormSubtitle>{job.tagline}</FormSubtitle>
                <p className="display-linebreak">
                    {job.brief}
                </p>
            </>
        )
    }

    const componentPairs = [
        {
            title: (<FormTitle>Your invite</FormTitle>),
            component: getBottomLeftComponent()
        }
    ];
    if(pitches.length > 0){
        componentPairs.push({
            title: (<FormTitle>Why should you pitch?</FormTitle>),
            component: getBottomRightComponent()
        });
    }
    componentPairs.push({
        title: (<FormTitle>More about the job</FormTitle>),
        component: getMoreDetailsComponent()
    })

    return(
        <MultiPaneContainer
            columns={2}
            componentPairs={componentPairs}
        />
    );
}