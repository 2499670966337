// eslint-disable-next-line import/no-namespace
import * as yup from "yup";

export const required = yup.string().required('This field is required');
const email = yup.string().required('Required').email('Invalid email address');
const password = yup.string().required('Enter a password').matches(/^.*(?=.{8,})((?=.*[!-/:-@\\[-`{-~]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character");
const confirmPassword = yup.string().oneOf([yup.ref('password')], 'Passwords must match').required('Enter a password');
const confirmationCode = yup.string().required("Enter confirmation code").matches(/^[0-9]+$/, "Must be only digits").min(6, 'Must be exactly 6 digits').max(6, 'Must be exactly 6 digits');
const country = yup.string().required('Required');
const locationSearch = yup.object().required('Required');
const locality = yup.string().required('Required');
const postcode = yup.string().required('Required');
const state = yup.string().required('Required');
const mobileNumber = yup.string().required('Required').matches(/^[0-9 /+]+$/, "Must be only digits");
const firstName = yup.string().required('Required');
const lastName = yup.string().required('Required');
const lastNameOptional = yup.string();
const organisationName = yup.string().required('Required');
const roleType = yup.string().required('Required');
const termsAgreed = yup.bool().required('Terms must be accepted').oneOf([true], 'Terms must be accepted');
const pitchLength = yup.number().required('Required').min(30).max(180);
const updateExistingPitches = yup.bool().required('Required');

const emailSchema = {
  email
}

const mobileSchema = {
  mobileNumber
}

const confirmationCodeSchema = {
  confirmationCode
}

const loginSchema = {
  email,
  password
}

const userInviteSchema = {
  firstName,
  email
}

const createAccountSchema = {
  ...loginSchema,
  confirmPassword
}

const resetPasswordSchema = {
  confirmationCode,
  password,
  confirmPassword
}

const changePasswordSchema = {
  password,
  confirmPassword
}

const name = {
  firstName,
  lastName
}

const nameOptionalLastNameSchema = {
  firstName,
  lastNameOptional
}

const location = {
    locationSearch,
    locality,
    state,
    postcode,
    country
}

const personalInfoSchema = {
  ...name,
  ...location,
  mobileNumber,
  termsAgreed
}

const updatePersonalInfoSchema = {
  ...name,
  ...location,
  mobileNumber,
  updateExistingPitches
}

const companyInfoSchema = {
  organisationName,
  ...location
}

const organisationUserSchema = {
  ...name,
  roleType,
  termsAgreed
}

const organisationNameSchema = {
  organisationName
}

const roleTypeSchema = {
  roleType
}

const sharingDetailsSchema = {
  shareFirstName: yup.bool().required().oneOf([true]),
  shareLastName: yup.bool().required(),
  shareEmailAddress: yup.bool().test('oneOfContactFieldsRequired', 'At least one contact detail required', function(value){
      // eslint-disable-next-line no-invalid-this
      const { shareMobileNumber } = this.parent;
      if (!shareMobileNumber) return value
      return true
    }),
  shareMobileNumber: yup.bool().test('oneOfContactFieldsRequired', 'At least one contact detail required', function(value){
      // eslint-disable-next-line no-invalid-this
      const { shareEmailAddress } = this.parent;
      if (!shareEmailAddress) return value
      return true
    }),
  shareLocation: yup.bool().required()
}

const jobDetailsSchema = {
  jobTitle: yup.string().required('Required').max(50, "Maximum of 50 characters"),
  tagline: yup.string().required('Required').max(50, "Maximum of 50 characters"),
  closingDate: yup.date().required('Required'),
  noClosingDate: yup.boolean().required('Required'),
  brief: yup.string().required('Required').min(10, "Must be at least 10 characters").max(500, "Max length is 500 characters")
}
const recruiterJobDetailsSchema = {
  ...jobDetailsSchema,
  organisationName: yup.string().required('Required').max(50, "Maximum of 50 characters")
}

const questionSchema = {
  questionText: yup.string().required('Required'),
  questionLength: pitchLength
}

const commentSchema = {
  comment: yup.string().required('Required').min(1, "Must be at least 1 character").max(500, "Max length is 500 characters")
}

const pitchLengthSchema = {
  pitchLength
}

const managerLocationDetailsSchema = {
  ...nameOptionalLastNameSchema,
  ...location
}

const locationSchema = {
  location
}

const bulkInviteColumnSchema = {
  Firstname: yup.string().required('Required').test('columnsMustBeDifferent', 'Columns must be different', function(value){
    // eslint-disable-next-line no-invalid-this
    const { Email } = this.parent;
    if (Email === value) return false;
    return value !== null && value !== "";
  }),
  Email: yup.string().required('Required').test('columnsMustBeDifferent', 'Columns must be different', function(value){
    // eslint-disable-next-line no-invalid-this
    const { Firstname } = this.parent;
    if (Firstname === value) return false;
    return value !== null && value !== "";
  }),
}

export function getYupSchema(schemaObject){
  return yup.object().shape(schemaObject);
}

export function getLoginSchema(){
  return getYupSchema(loginSchema);
}

export function getCreateAccountSchema(){
  return getYupSchema(createAccountSchema);
}

export function getResetPasswordSchema(){
  return getYupSchema(resetPasswordSchema);
}

export function getEmailSchema(){
  return getYupSchema(emailSchema);
}

export function getMobileSchema(){
  return getYupSchema(mobileSchema);
}

export function getConfirmationCodeSchema(){
  return getYupSchema(confirmationCodeSchema);
}

export function getPasswordChangeSchema(){
  return getYupSchema(changePasswordSchema);
}

export function getPersonalInfoSchema(){
  return getYupSchema(personalInfoSchema)
}

export function getCompanyInfoSchema(){
  return getYupSchema(companyInfoSchema);
}

export function getNameOptionalLastNameSchema(){
  return getYupSchema(nameOptionalLastNameSchema);
}

export function getOrganisationUserSchema(){
  return getYupSchema(organisationUserSchema);
}

export function getSharingDetailsSchema(){
  return getYupSchema(sharingDetailsSchema);
}

export function getJobDetailsSchema(){
  return getYupSchema(jobDetailsSchema);
}

export function getRecruiterJobDetailsSchema(){
  return getYupSchema(recruiterJobDetailsSchema);
}

export function getQuestionSchema(){
  return getYupSchema(questionSchema);
}

export function getPitchLengthSchema(){
  return getYupSchema(pitchLengthSchema);
}

export function getUpdatePersonalInfoSchema(){
  return getYupSchema(updatePersonalInfoSchema);
}

export function getManagerLocationDetailsSchema(){
  return getYupSchema(managerLocationDetailsSchema);
}

export function getUserInviteSchema(){
  return getYupSchema(userInviteSchema);
}

export function getOrganisationNameSchema(){
  return getYupSchema(organisationNameSchema);
}

export function getRoleTypeSchema(){
  return getYupSchema(roleTypeSchema);
}

export function getLocationSchema(){
  return getYupSchema(locationSchema);
}

export function getBulkInviteColumnSchema(){
  return getYupSchema(bulkInviteColumnSchema);
}

export function getCommentSchema(){
  return getYupSchema(commentSchema);
}

export async function validateEmail(email){
  const schema = yup.string().email();
  return await schema.isValid(email);
}