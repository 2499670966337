import { Col, Form, Row } from "react-bootstrap";

export default function EmailField({values, handleChange, handleBlur, touched, errors, autoFocus = true}){
    return(
    <Form.Group as={Row} controlId="email" size="lg" className="my-3">
        <Form.Label column sm={3}>Email</Form.Label>
        <Col sm={9} className="ps-1">
        <Form.Control
            autoFocus={autoFocus}
            type="email"
            autoComplete="username"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.email && !errors.email}
            isInvalid={touched.email && errors.email}
        />
        <Form.Control.Feedback type="invalid">
            {errors.email}
        </Form.Control.Feedback>
        </Col>
    </Form.Group>
  )
}