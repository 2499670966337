import { Card } from "react-bootstrap";
import InviteDetails from "./InviteDetails";

export default function InviteCard({
    jobTitle,
    location,
    createdDate,
    closingDate,
    actionComponent

}){
    return(
        <Card>
            <Card.Header className="container-outline-primary-0">
                <span className="fw-bold">{jobTitle}</span>
            </Card.Header>
            <Card.Body className="p-0">
                <InviteDetails jobLocation={location} createdDate={createdDate} closingDate={closingDate} />
            </Card.Body>
            <Card.Footer className="text-center">
                {actionComponent}
            </Card.Footer>
        </Card>
    );
}