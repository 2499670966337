import React, { lazy } from "react";
import { Switch } from "react-router-dom";
import Home from "./containers/home/Home";
import HomeLandingPage from "./containers/home/HomeLandingPage";
import HomeRoute from "./components/routes/HomeRoute";
import AuthenticatedRoute from "./components/routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/routes/UnauthenticatedRoute";
import CandidateMixedRoute from "./components/routes/CandidateMixedRoute";
import SignupRoute from "./components/routes/SignupRoute";
import OrgSignupRoute from "./components/routes/OrgSignupRoute";
import { Link } from "./libs/shared/model";
import { statusFilterTypes } from "./libs/shared/enums";
import StandardPageContainer from "./components/layout/StandardPageContainer";
import OrgAuthenticatedRoute from "./components/routes/OrgAuthenticatedRoute";
import CandidateAuthenticatedRoute from "./components/routes/CandidateAuthenticatedRoute";
import OrgMixedRoute from "./components/routes/OrgMixedRoute";
import { Suspense } from "react";
import LoadingContainer from "./components/layout/LoadingContainer";
import PitchRoute from "./components/routes/PitchRoute";

const LoginPathChoice = lazy(() => import("./containers/sign-in/LoginPathChoice"));
const Login = lazy(() => import("./containers/sign-in/Login"));
const SignUpWizard = lazy(() => import("./containers/sign-up-wizard/SignUpWizard"));
const OrganisationSignUpWizard = lazy(() => import("./containers/sign-up-wizard/OrganisationSignUpWizard"));
const ResetPasswordWizard = lazy(() => import("./containers/sign-in/ResetPasswordWizard"));
const Links = lazy(() => import("./containers/links/Links"));
const LinksConvert = lazy(() => import("./containers/links/LinksConvert"));
const InviteContainer = lazy(() => import("./containers/invites/InviteContainer"));
const InviteAnalytics = lazy(() => import("./containers/invites/InviteAnalytics"));
const ApplicationWizard = lazy(() => import("./containers/applications/ApplicationWizard"));
const ApplicationContainer = lazy(() => import("./containers/applications/ApplicationContainer"));
const JobsSharedWithMe = lazy(() => import("./containers/jobs/JobsSharedWithMe"));
const CandidateFeed = lazy(() => import("./containers/jobs/CandidateFeed"));
const JobWizard = lazy(() => import("./containers/jobs/JobWizard"));
const JobContainer = lazy(() => import("./containers/jobs/JobContainer"));
const CandidateContainer = lazy(() => import("./containers/candidate/CandidateContainer"));
const JobAnalytics = lazy(() => import("./containers/jobs/JobAnalytics"));
const CandidateSettings = lazy(() => import("./containers/settings/candidate/CandidateSettings"));
const OrganisationSettings = lazy(() => import("./containers/settings/organisation/OrganisationSettings"));
const Products = lazy(() => import("./components/products/Products"));
const LegalContainer = lazy(() => import("./containers/legal/LegalContainer"));
const PrivacyContainer = lazy(() => import("./containers/legal/PrivacyContainer"));
const PricingContainer = lazy(() => import("./containers/marketing/PricingContainer"));
const NotFound = lazy(() => import("./containers/NotFound"));

export default function Routes() {
  return (
    <Switch>
      <HomeRoute title="Video interview software" exact path="/"
        authenticatedComponent={
          <Home />
        }
        unauthenticatedComponent={
          <HomeLandingPage />
        } />

      <UnauthenticatedRoute title="Login - select account type" exact showLoginNav={false} path="/login_choice">
        <Suspense fallback={<LoadingContainer/>}>
          <LoginPathChoice />
        </Suspense>
      </UnauthenticatedRoute>
      <UnauthenticatedRoute title="Candidate login" exact showLoginNav={false} path="/login">
        <Suspense fallback={<LoadingContainer/>}>
          <Login />
        </Suspense>
      </UnauthenticatedRoute>
      <UnauthenticatedRoute title="Organisation login" exact showLoginNav={false} path="/org_login">
        <Suspense fallback={<LoadingContainer/>}>
          <Login isOrganisation={true} />
        </Suspense>
      </UnauthenticatedRoute>

      <SignupRoute title="Candidate signup" exact showLoginNav={false} path="/signup">
        <Suspense fallback={<LoadingContainer/>}>
          <SignUpWizard />
        </Suspense>
    </SignupRoute>
      <OrgSignupRoute title="Organisation signup" exact showLoginNav={false} path="/org_signup">
        <Suspense fallback={<LoadingContainer/>}>
          <OrganisationSignUpWizard />
        </Suspense>
      </OrgSignupRoute>
      <OrgSignupRoute title="Organisation user signup" exact showLoginNav={false} path="/org_join">
        <Suspense fallback={<LoadingContainer/>}>
          <OrganisationSignUpWizard isExistingOrganisation />
        </Suspense>
      </OrgSignupRoute>
      <OrgMixedRoute title="Join organisation account" exact showLoginNav={false} path="/links/user/:id">
        <Suspense fallback={<LoadingContainer/>}>
          <Links type={Link.linkTypes.JOIN_ACCOUNT} />
        </Suspense>
      </OrgMixedRoute>

      <UnauthenticatedRoute title="Reset password" exact path="/org_login/reset">
        <Suspense fallback={<LoadingContainer/>}>
          <ResetPasswordWizard isOrganisation={true} />
        </Suspense>
      </UnauthenticatedRoute>
      <UnauthenticatedRoute title="Reset password" exact path="/login/reset">
        <Suspense fallback={<LoadingContainer/>}>
          <ResetPasswordWizard isOrganisation={false} />
        </Suspense>
      </UnauthenticatedRoute>

      <CandidateMixedRoute title="Your invitation to apply" exact showLoginNav={false} path="/links/invite/:id">
        <Suspense fallback={<LoadingContainer/>}>
          <Links type={Link.linkTypes.INVITE} />
        </Suspense>
      </CandidateMixedRoute>
      <CandidateAuthenticatedRoute title="Your invitation to apply" exact path="/links/invite/:id/convert">
        <Suspense fallback={<LoadingContainer/>}>
          <LinksConvert type={Link.linkTypes.INVITE} />
        </Suspense>
      </CandidateAuthenticatedRoute>
      <CandidateAuthenticatedRoute title="Your invitation to apply" exact path="/invites/:id">
        <Suspense fallback={<LoadingContainer/>}>
          <InviteContainer />
        </Suspense>
      </CandidateAuthenticatedRoute>

      <CandidateAuthenticatedRoute title="Editing your application" exact path="/invites/:inviteId/application/edit">
        <Suspense fallback={<LoadingContainer/>}>
          <ApplicationWizard />
        </Suspense>
      </CandidateAuthenticatedRoute>
      <CandidateAuthenticatedRoute title="Your application" exact path="/invites/:inviteId/application/view">
        <Suspense fallback={<LoadingContainer/>}>
          <ApplicationContainer />
        </Suspense>
      </CandidateAuthenticatedRoute>
      <CandidateAuthenticatedRoute title="Your application analytics" exact path="/invites/:inviteId/analytics">
        <Suspense fallback={<LoadingContainer/>}>
          <InviteAnalytics />
        </Suspense>
      </CandidateAuthenticatedRoute>

      <OrgAuthenticatedRoute title="Join organisation account" exact path="/links/user/:id/convert">
        <Suspense fallback={<LoadingContainer/>}>
          <LinksConvert type={Link.linkTypes.JOIN_ACCOUNT} />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgMixedRoute title="Invite to shared applicant list" exact showLoginNav={false} path="/links/sharedjob/:id">
        <Suspense fallback={<LoadingContainer/>}>
          <Links type={Link.linkTypes.APPLICANT_LIST} />
        </Suspense>
      </OrgMixedRoute>
      <OrgAuthenticatedRoute title="Jobs shared with my organisation" exact path="/sharedjobs">
        <Suspense fallback={<LoadingContainer/>}>
          <JobsSharedWithMe />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Invite to shared applicant list" exact path="/links/sharedjob/:id/convert">
        <Suspense fallback={<LoadingContainer/>}>
          <LinksConvert type={Link.linkTypes.APPLICANT_LIST} />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Candidate application" exact path="/invites/:id">
        <Suspense fallback={<LoadingContainer/>}>
          <InviteContainer />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Candidates for job" exact path="/organisations/:orgId/jobs/:jobId/review">
        <Suspense fallback={<LoadingContainer/>}>
          <CandidateFeed />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Shortlisted candidates for job" exact path="/organisations/:orgId/jobs/:jobId/shortlist">
        <Suspense fallback={<LoadingContainer/>}>
          <CandidateFeed filterOptions={[statusFilterTypes.SHORTLISTED]} />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Editing your job" exact path="/organisations/:orgId/jobs/:jobId/edit">
        <Suspense fallback={<LoadingContainer/>}>
          <JobWizard />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Your job" exact path="/organisations/:orgId/jobs/:jobId/view">
        <Suspense fallback={<LoadingContainer/>}>
          <JobContainer />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Candidate application for job" exact path="/organisations/:orgId/jobs/:jobId/users/:userId/invites/:inviteId">
        <Suspense fallback={<LoadingContainer/>}>
          <CandidateContainer />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Your job analytics" exact path="/organisations/:orgId/jobs/:jobId/analytics">
        <Suspense fallback={<LoadingContainer/>}>
          <JobAnalytics />
        </Suspense>
      </OrgAuthenticatedRoute>

      <AuthenticatedRoute title="Settings" exact path="/settings">
        <Suspense fallback={<LoadingContainer/>}>
          <CandidateSettings />
        </Suspense>
      </AuthenticatedRoute>
      <OrgAuthenticatedRoute title="Settings" exact path="/org_settings">
        <Suspense fallback={<LoadingContainer/>}>
          <OrganisationSettings />
        </Suspense>
      </OrgAuthenticatedRoute>
      <OrgAuthenticatedRoute title="Select subscription" exact path="/select_subscription">
        <Suspense fallback={<LoadingContainer/>}>
          <Products showFreeTier={false} />
        </Suspense>
      </OrgAuthenticatedRoute>

      <PitchRoute title="Terms" exact path="/legal">
        <Suspense fallback={<LoadingContainer/>}>
          <LegalContainer />
        </Suspense>
      </PitchRoute>

      <PitchRoute title="Pricing" exact path="/pricing">
        <Suspense fallback={<LoadingContainer/>}>
          <PricingContainer />
        </Suspense>
      </PitchRoute>

      <PitchRoute title="Privacy" exact path="/privacy">
        <Suspense fallback={<LoadingContainer/>}>
          <PrivacyContainer />
        </Suspense>
      </PitchRoute>

      <PitchRoute title="Not found">
        <StandardPageContainer backButton>
          <Suspense fallback={<LoadingContainer/>}>
            <NotFound />
          </Suspense>
        </StandardPageContainer>
      </PitchRoute>
    </Switch>
  );
}