import { Container, Row, Col, ToggleButton, ToggleButtonGroup, Badge } from "react-bootstrap";
import {statusFilterTypes, statusFilterTypeNames, viewTypes, viewTypeNames} from "../../libs/shared/enums";
import { PitchError } from "../../libs/shared/exceptions";
import PitchButton from "./PitchButton";

export default function FeedToolbar({
    filter = {status: statusFilterTypes.ALL, view: viewTypes.LIST}, 
    setFilter,
    filterOptions = [statusFilterTypes.ALL, statusFilterTypes.ACTIVE, statusFilterTypes.CLOSED],
    filterOptionsMeta = {},
    viewOptions = [viewTypes.FEED, viewTypes.LIST],
    showOptions = true,
    actionButton,
    allowExport = false,
    onExport = null,
    exportDisabled = false
}){
    if(actionButton & showOptions) throw new PitchError("Invalid combination of actionButton and showOptions");
    return(
        <>
            {filter && (<Container className="my-3">
                {allowExport && (<Row className="mb-2">
                    <Col className="d-flex justify-content-end">
                        <PitchButton variant="success"
                            onClick={onExport} 
                            isLoading={false}
                            disabledTooltip={"Nothing to export"}
                            disabled={exportDisabled}>Export
                        </PitchButton>
                    </Col>
                </Row>)}
                <Row>
                    {showOptions && (<Col xs={12} md={8} className="mb-2 d-flex justify-content-md-start justify-content-start">
                        <ToggleButtonGroup type="radio" name="view_filter" value={filter.status} onChange={(val) => setFilter({...filter, status: val})}>
                            {filterOptions.map((option) => 
                                <ToggleButton 
                                    id={"filter" + option}
                                    key={"filter" + option} 
                                    variant={statusFilterTypeNames[option].variant ? statusFilterTypeNames[option].variant : "outline-primary"} 
                                    value={option}>
                                        {statusFilterTypeNames[option].text}{(filterOptionsMeta[option] || filterOptionsMeta[option] === 0) ? <> <Badge pill bg="light" text="primary">{filterOptionsMeta[option]}</Badge></> : ""}
                                </ToggleButton>)}
                        </ToggleButtonGroup>
                    </Col>)}
                    {showOptions && viewOptions.length > 1 && (
                        <Col xs={12} md={4} className="d-flex justify-content-md-end justify-content-start mb-2">
                            <ToggleButtonGroup type="radio" name="feed_mode" defaultValue={filter.view} onChange={(val) => setFilter({...filter, view: val})}>
                                {viewOptions.map((option) => <ToggleButton id={"view" + option} key={"view" + option} variant="outline-complement" value={option}>{viewTypeNames[option]}</ToggleButton>)}
                            </ToggleButtonGroup>
                        </Col>
                    )}
                    {(!showOptions || viewOptions.length <= 1) && actionButton && (<Col className="d-flex justify-content-end">
                        {actionButton}
                    </Col>)}
                </Row>
            </Container>)}
        </>
    );
}