import {ListGroup, Row, Col} from "react-bootstrap";
import {AlarmFill, Building, ClockHistory} from "react-bootstrap-icons";
import {stringifyLocation} from "../../libs/utilities";
import { Countdown } from "../text/Countdown";
import { Countup } from "../text/Countup";

export default function InviteDetails(props){
    return (
        <ListGroup variant="flush">
            {props.jobLocation && (<ListGroup.Item key="location">
                <Row>
                    <Col md={4} xs={4}><Building /> Location: </Col> 
                    <Col md={8} xs={8} className="text-end">
                        {stringifyLocation(props.jobLocation)}
                    </Col>
                </Row>
            </ListGroup.Item>)}
            {props.createdDate && (<ListGroup.Item key="inviteDate">
                <Row>
                    <Col md={4} xs={4}><ClockHistory /> Invite date:</Col> 
                    <Col md={8} xs={8} className="text-end">
                        <Countup time={props.createdDate} periods={['years', 'months', 'days', 'hours', 'minutes']}/>
                    </Col>
                </Row>
            </ListGroup.Item>)}
            {props.closingDate && (<ListGroup.Item key="inviteClosingDate">
                <Row>
                    <Col md={4} xs={4}><AlarmFill /> Time left:</Col> 
                    <Col md={8} xs={8} className="text-end">
                        <Countdown deadline={props.closingDate} periods={['years', 'months', 'days', 'hours', 'minutes']}/>
                    </Col>
                </Row>
            </ListGroup.Item>)}
        </ListGroup>  
    );
}