import InviteCmp from "./InviteCmp";

export default function InviteFeedItem({action, item}){
    return(
        <InviteCmp invite={{
            organisationName: item.job.organisationName,
            jobTitle: item.job.jobTitle,
            createdDate: item.createdDate,
            closingDate: item.closingDate
        }} pitches={item.job.pitches} job={item.job} actionComponent={null} />
    )
}