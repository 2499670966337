import { ButtonToolbar } from "react-bootstrap"
import { EyeFill, GraphUp, Pencil, PlayFill } from "react-bootstrap-icons"
import { useHistory } from "react-router";
import { createApplicationForInvite } from "../../api/lambda_api";
import { useErrorContext } from "../../libs/contextLib";
import { Invite, Permission } from "../../libs/shared/model";
import { PitchError } from "../../libs/shared/exceptions";
import PitchButton from "../buttons/PitchButton";

export default function InviteActionsBar({item, getLinkFunction}){

    const {setError} = useErrorContext();
    const history = useHistory();

    async function goToView(){
        history.push(`/invites/${item.inviteId}`);
    }
    async function goToAnalytics(){
        history.push(`/invites/${item.inviteId}/analytics`);
    }

    async function goToEdit(){
        if(!item.application){
            try{
                await createApplicationForInvite(item);
                history.push(`/invites/${item.inviteId}/application/edit?step=0`);
            } catch (e) {
                setError(new PitchError(e.message, e));
            }
        }else{
            history.push(`/invites/${item.inviteId}/application/edit?step=0`);
        }
    }

    async function goToReview(){
        history.push(`/invites/${item.inviteId}/application/view`);
    }

    return(
        <ButtonToolbar>
            {item.status === Invite.inviteStatusTypes.APP_SUBMITTED && (
                <PitchButton 
                    tooltip="View application analytics" 
                    className="btn-circle p-0 m-2" 
                    size="md" 
                    variant="outline-secondary" 
                    onClick={goToAnalytics}>
                        <GraphUp size={item.userPermission.permission < Permission.permissionTypes.VIEW_OWNER ? 14 : 18}/>
                </PitchButton>
            )}
            <PitchButton className="btn-circle p-0 m-2" size="md" variant="outline-secondary" onClick={goToView}><EyeFill size={18}/></PitchButton>
            {item.status !== Invite.inviteStatusTypes.APP_SUBMITTED && (<PitchButton className="btn-circle p-0 m-2" size="md" variant="outline-secondary" onClick={goToEdit}><Pencil size={18}/></PitchButton>)}
            {item.status === Invite.inviteStatusTypes.APP_SUBMITTED && (<PitchButton className="btn-circle p-0 m-2" size="md" variant="outline-secondary" onClick={goToReview}><PlayFill size={18}/></PitchButton>)}
        </ButtonToolbar>
    )
}