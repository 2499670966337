import React, { useEffect } from "react";
import { isOrgAccountActive } from "../../api/localstorage_api";
import { useAppContext } from "../../libs/contextLib";
import PlaceholderPage from "../layout/PlaceholderPage";
import PitchRoute from "./PitchRoute";

export default function OrgMixedRoute({ children, showLoginNav = true, title = null, ...rest }) {

  const { isAuthenticated } = useAppContext();
  const { setShowLoginNav } = useAppContext();

  useEffect(() => {
    setShowLoginNav(showLoginNav);
  });

  return (
    <PitchRoute title={title} {...rest}>
        {(isAuthenticated && !isOrgAccountActive()) ? (
            <PlaceholderPage>
                <h3>Sorry! This is an organisation only page, please log in with an organisation account to view.</h3>
            </PlaceholderPage>
        ) : (
            children
        )}
    </PitchRoute>
  );
}