import { Card, Col, Container, Row } from "react-bootstrap";
import { renderListBar } from "./FeedListItem";

export default function FeedCardItem({LinkComponent, item, listComponent, itemFeedComponent, toolbarComponent, itemFeedFooterComponent}){
    return(
        <Card className="mb-4">
            <Card.Body className="pt-2 pb-0 px-0">
                <Container className="p-0">
                    <Row className="px-3 pb-2 m-0">{renderListBar(LinkComponent, item, listComponent, toolbarComponent)}</Row>
                    <Row className="p-0 m-0 border-top">
                        <Col>
                            {itemFeedComponent}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            {itemFeedFooterComponent && (<Card.Footer>{itemFeedFooterComponent}</Card.Footer>)}
        </Card>
    )
}