import React, { useCallback, useState } from "react";
import {useHistory} from "react-router-dom";
import {PitchError} from "../../libs/shared/exceptions";
import JobListItem from "../../components/jobs/JobListItem";
import {useErrorContext, useInfoContext} from "../../libs/contextLib";
import {createJob, createStripePortalSession, loadJobs} from "../../api/lambda_api";
import PitchButton from "../../components/buttons/PitchButton";
import FeedContainer from "../../components/layout/FeedContainer";
import JobFeedItem from "../../components/jobs/JobFeedItem";
import JobActionBar from "../../components/jobs/JobActionsBar";
import JobLinkContainer from "../../components/jobs/JobLinkContainer";
import { statusFilterTypes } from "../../libs/shared/enums";
import StandardPageContainer from "../../components/layout/StandardPageContainer";
import OrganisationNavbar from "../../components/layout/OrganisationNavbar";
import { Job, Permission } from "../../libs/shared/model";
import { useOrganisation, usePermissions } from "../../libs/hooksLib";
import { appConfig } from "../../libs/shared/app-config";

export default function HomeOrganisation() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {organisation} = useOrganisation();
  const {setError} = useErrorContext();
  const {setUserMessage} = useInfoContext();
  const history = useHistory();
  const {getPermission} = usePermissions();

	const loadJobsCallback = useCallback(async(filter, lastEvaluatedKey) => {
		const now = new Date();
		const startRange = filter.status === statusFilterTypes.ACTIVE ? now.getTime() : null; 
		const endRange = filter.status === statusFilterTypes.CLOSED ? now.getTime() : null;
		const jobSearchResult = await loadJobs(organisation.orgId, startRange, endRange, lastEvaluatedKey);
		return jobSearchResult;
	},[organisation.orgId]);

	async function redirectToSubscriptions(){
        setUserMessage({
            title: "Subscription limit reached",
            body: "You've reached the limit of your subscription. You can either expire some jobs, or increase your subscription level.",
            buttonText: (organisation.hasActiveSubscription() ? "Manage subscription" : "Upgrade susbcription"),
            cancelButtonText: "Cancel",
            callback: async() => {
              if(!organisation.hasActiveSubscription() || organisation.subscription.status === 'free') {
				  history.push('/select_subscription');
			  }else{
				try{
					const completionPath = "org_settings";
					const session = await createStripePortalSession(organisation.subscription.customerId, completionPath);
					window.location.assign(session.stripeUrl);
				}catch(e){
				  setError(e);
				}
			  }
            }
        });
	}

	async function startNewJobPosting(){
		setIsSubmitting(true);
		try{
			const orgId = organisation.orgId;
			const job = await createJob(orgId);
			history.push(`/organisations/${orgId}/jobs/${job.jobId}/edit?step=0`);
		} catch (e) {
            setError(new PitchError(e.message, e));
		}
		setIsSubmitting(false);
	}

  function renderJobsList() {
	  return (
	    <>
			<FeedContainer 
				itemIdFunction={(job) => job.jobId}
				ItemListComponent={JobListItem} 
				ItemFeedComponent={JobFeedItem} 
				loadItemsFunction={loadJobsCallback}
				ItemToolbarComponent={JobActionBar}
				ItemLinkComponent={JobLinkContainer} 
				filterOptions={[statusFilterTypes.ALL, statusFilterTypes.ACTIVE, statusFilterTypes.CLOSED]}
				filterOptionsMeta={
					{
						[statusFilterTypes.ALL]: organisation.getCountByKeyFilter(key => key.startsWith("Job") && key !== "Job" + Job.jobStatusTypes.DELETED),
						[statusFilterTypes.ACTIVE]: organisation.countOfActiveJobs(),
						[statusFilterTypes.CLOSED]: organisation.getCountByKeyFilter(key => key.startsWith("Job") && key === "Job" + Job.jobStatusTypes.EXPIRED)
					}}
				additionalProperties={{isOrgSharingAllowed: organisation.isRecruiter}}/>
	    </>
	  );
	}

  return (
		<StandardPageContainer navbarComponent={<OrganisationNavbar/>} title={"My jobs"} headerRHSComponent={
			<PitchButton variant="success"
				onClick={startNewJobPosting} 
				isLoading={isSubmitting}
				isWarning={organisation.hasReachedLimit(appConfig.freeTier)}
				warningTooltip={"You have reached your subscription limit"}
				warningOnClick={redirectToSubscriptions}
				disabledTooltip={"You do not have permission to perform this action"}
				disabled={!getPermission(Job.generateKey(organisation.orgId, "*"), Permission.permissionTypes.CREATE)}>Post new job
			</PitchButton>}>
				{renderJobsList()}
		</StandardPageContainer>
  );
}