import { BriefcaseFill, ShareFill } from "react-bootstrap-icons";
import TabsNav from "../buttons/TabsNav";

export default function OrganisationNavbar(){
    return(
        <TabsNav variant="navy" items={
            [
                {text: "My jobs", url: "/", iconCmp: <BriefcaseFill className="me-3" />},
                {text: "Jobs shared with me", url: "/sharedjobs", iconCmp: <ShareFill className="me-3" />}
            ]
        } />
    );
}