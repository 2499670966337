import { Col, Container, Row, Table } from "react-bootstrap";

export default function PreviewTable({title = null, headers, records}){
    return(
        <Container>
            {title && (<Row className="mt-5"><Col><h5>{title}</h5></Col></Row>)}
            <Row>
                <Table responsive striped size="sm">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record, index) => (
                            <tr key={index}>
                                {typeof record === 'object' ? headers.map((header, colIndex) => (
                                    <td key={colIndex}>{record[header]}</td>
                                )) : record.map((value, colIndex) => (
                                    <td key={colIndex}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}