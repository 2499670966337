import { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useDimensions } from "../../libs/hooksLib";

export default function MultiPaneContainer({
    componentPairs,
    columns = 2
}){
    const {bootstrapSize} = useDimensions().window;

    const tempArray = [...componentPairs];
    const grid = [];
    const cols = (bootstrapSize === 'lg' || bootstrapSize === 'xl' || bootstrapSize === 'xxl') ? columns : 1;
    while(tempArray.length > 0){
        for(let x = 0; x < cols; x++){
            if(tempArray[x]){
                grid.push(tempArray[x].title);
            }
        }
        for(let x = 0; x < cols; x++){
            if(tempArray[x]){
                grid.push(tempArray[x].component);
            }
        }
        for(let x = 0; x < cols; x++){
            if(tempArray[0]){
                tempArray.shift();
            }
        }
    }

    const calcLargeSpan = useCallback((index) => {
        const cmpIndex = Math.floor(index/2);
        const lastOddRow = cmpIndex >= (componentPairs.length - componentPairs.length % columns) && componentPairs.length % columns !== 0;
        if(!lastOddRow) return 12 / columns;
        return 12 / (componentPairs.length % columns);
    }, [columns, componentPairs.length]);

    return(
        <Row>
            {grid.map((item, index) => (
                <Col key={"item" + index} lg={{span: calcLargeSpan(index)}} xs={{span: 12}} className="mb-3">
                    {item}
                </Col>
            ))}
        </Row>
    )
}