import InviteCmp from "../invites/InviteCmp";

export default function JobFeedItem({action, item}){
    return(
        <InviteCmp invite={{
            organisationName: item.organisationName,
            jobTitle: item.jobTitle,
            createdDate: item.createdDate,
            closingDate: item.closingDate
        }} pitches={item.pitches} job={item} actionComponent={null} />
    )
}