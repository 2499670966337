import {useState, useEffect, useCallback} from "react";
import { useErrorContext, useInfoContext } from "../../libs/contextLib";
import logger from "../../libs/errorLib";
import { useScrollStatus } from "../../libs/hooksLib";
import FeedToolbar from "../buttons/FeedToolbar";
import {statusFilterTypes, viewTypes} from "../../libs/shared/enums";
import { Col, Container, Row } from "react-bootstrap";
import LoadingImage from "./LoadingImage";
import CricketsImage from "./CricketsImage";
import FeedListItem from "./FeedListItem";
import FeedCardItem from "./FeedCardItem";
import PitchButton from "../buttons/PitchButton";
import { Storage } from "aws-amplify";
import DownloadModal from "../sharing/DownloadModal";

export default function FeedContainer({
    itemIdFunction, 
    initialFilter = null, 
    loadItemsFunction, 
    ItemToolbarComponent,
    ItemLinkComponent,
    ItemListComponent,
    ItemFeedComponent, 
    ItemFeedFooterComponent,
    additionalProperties,
    reloadThreshold = 2,
    filterOptions,
    filterOptionsMeta,
    viewOptions,
    showOptions = true,
    actionButton = null,
    allowExport = false,
    identityId = null,
    paginate = false
}){

	// eslint-disable-next-line no-unused-vars
	const [isListLoading, setIsListLoading] = useState(true);
    const [filter, setFilter] = useState(initialFilter ? initialFilter : {status: statusFilterTypes.ALL, view: viewTypes.LIST});
    const [items, setItems] = useState([]);
	const [lastEvaluatedKey, setLastEvaluatedKey] = useState();
    const [pageKeys, setPageKeys] = useState([]);
    const {setError} = useErrorContext();
    const {setUserMessageModal} = useInfoContext();
	const {bottom} = useScrollStatus();
	const [triggerLoad, setTriggerLoad] = useState(false);

    const showToolbar = (filterOptions && filterOptions.length > 0) || (viewOptions && viewOptions.length > 0);

	const loadItems = useCallback(async(filter, startKey = null) => {
        try{
            const searchResult = await loadItemsFunction(filter, startKey);
            if(!paginate){
                //If the lastevaluatedkey was populated that means we're expanding a list. If it's null, then it's a fresh list
                if(startKey){
                    setItems((prevState) => [...prevState, ...searchResult.items]);
                }else{
                    setItems(searchResult.items);
                }
            }else{
                setItems(searchResult.items);
            }
            setLastEvaluatedKey(searchResult.startKey);
            return searchResult.startKey;
        }catch(e){
            setError(e);
        }
	},[loadItemsFunction, paginate, setError]);

    const exportItems = useCallback(async() => {
        try{
            setUserMessageModal({component: DownloadModal});
            const exportResult = await loadItemsFunction(filter, null, true);
            const params = {
                level: "private",
                download: false,
                expires: 1800,
                identityId
            }
            const downloadLink = await Storage.get(exportResult.Filename, params);
            setUserMessageModal({component: DownloadModal, props: {downloadLink}});
        }catch(e){
            setError(e);
        }
    },[setUserMessageModal, loadItemsFunction, filter, identityId, setError]);

	//Load applications, changing if the feed changes. Runs at start.
	useEffect(() => {
		async function onFilterChange() {
			try{
				setIsListLoading(true);
				setLastEvaluatedKey(null);
				const newKey = await loadItems(filter, null);
                if(newKey){
                    setPageKeys([newKey]);
                }else{
                    setPageKeys([]);
                }
				setIsListLoading(false);
			}catch(e){
				setError(e);
			}
		}
		if(filter) onFilterChange();
	}, [filter, loadItems, setError]);

    async function prevPage() {
        try{
            setIsListLoading(true);
            const newPageKeys = pageKeys.slice(0, -2);
            const newKey = await loadItems(filter, newPageKeys.length === 0 ? null : newPageKeys[newPageKeys.length - 1]);
            newPageKeys.push(newKey);
            setPageKeys(newPageKeys);
            setIsListLoading(false);
        }catch(e){
            setError(e);
        }
    }
    async function nextPage() {
        try{
            setIsListLoading(true);
            const newKey = await loadItems(filter, pageKeys[pageKeys.length - 1]);
            setPageKeys(prevState => [...prevState, newKey]);
            setIsListLoading(false);
        }catch(e){
            setError(e);
        }
    }

    // Function for triggering reload on bottom
	useEffect(() => {
		async function onBottom() {
			try{
				setIsListLoading(true);
				setTriggerLoad(false);
				logger.debug("Scroll reached bottom, loading more items");
				await loadItems(filter, lastEvaluatedKey);
				setIsListLoading(false);
			}catch(e){
				setError(e);
			}
		}
		if((bottom || triggerLoad) && lastEvaluatedKey && !isListLoading && filter && !paginate) onBottom();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bottom, triggerLoad, filter, isListLoading]);

    function removeItem(itemId){
        const revisedItems = items.filter(item => itemIdFunction(item) !== itemId);
        setItems(revisedItems);
        if(revisedItems.length <= reloadThreshold) setTriggerLoad(true);
    }

    function renderListItem(item){
        return(
            <FeedListItem 
                key={itemIdFunction(item)} 
                item={item}
                LinkComponent={ItemLinkComponent}
                listComponent={<ItemListComponent item={item} removeItemFunction={removeItem} {...additionalProperties} />}
                toolbarComponent={ItemToolbarComponent ? <ItemToolbarComponent item={item} removeItemFunction={removeItem} filter={filter} {...additionalProperties} /> : null}
            />
        )
    }

    function renderFeedItem(item){
        return(
            <FeedCardItem
                key={itemIdFunction(item)}
                item={item}
                LinkComponent={ItemLinkComponent}
                listComponent={<ItemListComponent item={item} removeItemFunction={removeItem} filter={filter} {...additionalProperties} />}
                toolbarComponent={ItemToolbarComponent ? <ItemToolbarComponent item={item} items={items} filter={filter} setItems={setItems} setTriggerLoad={setTriggerLoad} {...additionalProperties} /> : null}
                itemFeedComponent={<ItemFeedComponent item={item} filter={filter} removeItemFunction={removeItem} {...additionalProperties} />}
                itemFeedFooterComponent={ItemFeedFooterComponent ? <ItemFeedFooterComponent item={item} /> : null}
            />
        )
    }

    return(
        <>
            {showToolbar && (<FeedToolbar showOptions={showOptions} actionButton={actionButton} filter={filter} setFilter={setFilter} filterOptions={filterOptions} filterOptionsMeta={filterOptionsMeta} viewOptions={viewOptions} allowExport={allowExport} exportDisabled={items.length <= 0} onExport={exportItems} {...additionalProperties} />)}
            <Container className="my-4">
                {
                    filter && (filter.view === viewTypes.FEED) ?
                        items.map((item) => renderFeedItem(item)) :
                        items.map((item) => renderListItem(item))
                }
                <Container className="my-5 py-5 d-flex align-items-center">
                    {isListLoading ? 
                        (
                            <LoadingImage/>
                        ) : items.length === 0 && (
                            <CricketsImage/>
                        )
                    }
                </Container>
            </Container>
            {paginate && (<Container className="my-3">
                <Row>
                    <Col className="d-flex justify-content-end">
                        <PitchButton className="me-2" isLoading={isListLoading} disabled={pageKeys.length <= 1} onClick={prevPage} variant="outline-secondary2">Prev page</PitchButton>
                        <PitchButton isLoading={isListLoading} disabled={!lastEvaluatedKey} onClick={nextPage} variant="outline-prime">Next page</PitchButton>
                    </Col>
                </Row>
            </Container>)}
        </>
    );

}