import { Formik } from "formik";
import { useRef, useState } from "react";
import {Modal, Container, Row, Col, Form} from "react-bootstrap";
import { getUserInviteSchema } from "../../libs/shared/schemas";
import FirstNameField from "../forms/fields/FirstNameField";
import EmailField from "../forms/fields/EmailField";
import { useErrorContext } from "../../libs/contextLib";
import { inviteOtherOrganisationToJob } from "../../api/lambda_api";
import PitchButton from "../buttons/PitchButton";
import InputWithCopy from "../forms/fields/InputWithCopy";
import { getFullUrl } from "../../libs/hooksLib";
import FormSubtitle from "../text/FormSubtitle";

export default function JobSharingModal({callback, job, allowUsers = false, allowOrganisation}) {

    const [page, setPage] = useState(0);
    const [shareWithOtherOrg, setShareWithOtherOrg] = useState(false);
    const [sharingUrl, setSharingUrl] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {setError} = useErrorContext();
    const emailFormRef = useRef();
    const [firstName, setFirstName] = useState();

    async function handleEmailFormSubmit(data){
        try{
            setIsLoading(true);
            setFirstName(data.firstName);
            const link = await inviteOtherOrganisationToJob(job, data.firstName, data.email);
            setSharingUrl(getFullUrl("links/sharedjob/" + link.linkId));
            setPage(prev => prev + 1);
            setIsLoading(false);
        }catch(e){
            setError(e);
        }
        setIsLoading(false);
    }

    function renderChoice(){
        return (
            <Row>
                <Col xs={12}>
                    <FormSubtitle>Who would you like to add to this job?</FormSubtitle>
                </Col>
                <Col xs={12} className="py-2">
                    <PitchButton block disabled={!allowUsers} disabledTooltip="This action is not available yet. Currently all users have access to all jobs." isLoading={isLoading} variant="complement">Another user</PitchButton>
                </Col>
                <Col xs={12} className="py-2">
                    <PitchButton block disabled={!allowOrganisation} disabledTooltip="Only available for recruiter accounts" isLoading={isLoading} onClick={() => {setShareWithOtherOrg(true); setPage(prev => prev + 1);}} variant="primary">Another organisation</PitchButton>
                </Col>
            </Row>
        )
    }

    function renderEmailInput(){
        return(
            <Container>
                <Row className="mb-3"><Col>Enter users contact information:</Col></Row>
                <Formik 
                    innerRef={emailFormRef}
                    validationSchema={getUserInviteSchema()}
                    onSubmit={handleEmailFormSubmit}
                    initialValues={{
                        firstName: "",
                        email: ""
                    }}
                    >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <FirstNameField autoFocus={true} values={values} handleChange={handleChange} handleBlur={handleBlur} touched={touched} errors={errors} />
                        <EmailField autoFocus={false} values={values} handleChange={handleChange} handleBlur={handleBlur} touched={touched} errors={errors} />
                    </Form>
                    )}
                </Formik>
            </Container>
        );
    }
    function renderConfirmation(){
        return(
            <Container>
                <Row className="mb-3"><Col>Success! {firstName} will receive an email invite. Need an additional link?</Col></Row>
                <InputWithCopy text={sharingUrl} />
            </Container>
        )
    }

    return (
        <>
            <Modal.Header className="container-secondary-2-2" closeButton>
                <Modal.Title>Invite another user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {page === 0 ? 
                    renderChoice()
                : 
                page === 1 && shareWithOtherOrg ?
                    renderEmailInput()
                :
                page === 2  && shareWithOtherOrg ?
                    renderConfirmation()
                : null}
            </Modal.Body>
            <Modal.Footer>
                    <PitchButton variant="secondary" onClick={() => callback()}>
                        Cancel
                    </PitchButton>
                {page !== 0 && (<PitchButton isLoading={isLoading} variant={"primary"} onClick={() => {
                    if(page === 2){
                        callback();
                    }else if (page === 1 && shareWithOtherOrg){
                        if(emailFormRef.current.isValid){
                            emailFormRef.current.handleSubmit();
                        }
                    }
                }}>
                    {page === 1 && shareWithOtherOrg ? "Submit" : "Ok"}
                </PitchButton>)}
            </Modal.Footer>
        </>
    );
}