import {DateTime} from "luxon";
import { useEffect, useState } from "react";
import { formatTimeDifference } from "../../libs/utilities";
import { useInterval } from "../../libs/hooksLib";

export function Countup({time, periods = ['years', 'months', 'days', 'hours', 'minutes'], round = false}){
    const [timeDifference, setTimeDifference] = useState(false);

    useEffect(() => {
        const now = DateTime.now();
        const then = DateTime.fromMillis(time);

        setTimeDifference(now.diff(then, ['years', 'months', 'days', 'hours', 'minutes']).values);
    }, [time]);

    useInterval(() => {
        const now = DateTime.now();
        const then = DateTime.fromMillis(time);

        setTimeDifference(now.diff(then, ['years', 'months', 'days', 'hours', 'minutes']).values);
    }, !timeDifference ? 0 : 60000);

    return(
        <>
            {timeDifference && formatTimeDifference(timeDifference, {periods, round}) + " ago"}
        </>
    )
}