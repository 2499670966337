import Storage from "@aws-amplify/storage";
import { v4 as uuidv4 } from 'uuid';
import { getFileExtension } from "../libs/shared/utils";

export async function uploadTemporaryFile(file, progressCallbackFnc){
    const fileId = uuidv4();
    const fileName = fileId + "." + getFileExtension(file.name);
    await Storage.put(fileName, file, {
        contentType: file.type,
        tagging: "expiration=1:day",
        level: "private",
        progressCallback(progress) {
            const prog = parseInt(progress.loaded/progress.total*100)
            progressCallbackFnc(prog);
        },
    });
    return fileName;
}

export async function deleteTemporaryFile(filename){
    await Storage.remove(filename, { level: "private" });
}