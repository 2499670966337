import React, {useEffect, useState, useCallback, useRef} from "react";
import {Storage} from "aws-amplify";
import {useInterval, useLogView} from "../../libs/hooksLib";
import { useErrorContext } from "../../libs/contextLib";
import { PitchError } from "../../libs/shared/exceptions";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import logger from "../../libs/errorLib";
import VideoPlayer from "../VideoPlayer";
import ResponsiveImage from "../layout/ResponsiveImage";

export default function PitchPlayer({
    pitchKey = null,
    user = null,
    orgId = null,
    logView = false,
    viewType = 'invite',
    mediaUri = null,
    level = "protected",
    identityId = null,
    originalHeight = 1080,
    originalWidth = 1920,
    encodedAvailable = false,
    notStartedPlaceholder = false,
    deletedPlaceholder = false,
    visible = true,
    autoPlay = false,
    playing = false,
    blur = false,
    showThumbnailInitially = true,
    placeholderText = "Pitch is still processing, this may take a few minutes...",
    onStarted,
    onProgressFrequent,
    onProgressInFrequent,
    onEnded,
    onSeek,
    onClickPreview,
    ...props
  }){

    const playerRef = useRef();
    const [mediaUrl, setMediaUrl] = useState();
    const [showThumbnailInternal, setShowThumbnailInternal] = useState(showThumbnailInitially);
    const [thumbnailUrl, setThumbnailUrl] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isMediaReady, setIsMediaReady] = useState(false);
    const [isTimedOut, setIsTimedOut] = useState(false);
    const {setError} = useErrorContext();
    const [pitchSecondsPlayed, setPitchSecondsPlayed] = useState(0);
    const pitchView = useLogView(user, pitchKey ? pitchKey : null, orgId, false);

    function onErrorHandler(e){
        logger.error("Failed to load or play video: " + e.message);
        setError(new PitchError("Failed to load or play video: " + e.message, e));
    }

    useEffect(() => {
        if(showThumbnailInternal) setShowThumbnailInternal(showThumbnailInitially);
    },[showThumbnailInitially, showThumbnailInternal]);

    useEffect(() => {
        if(!visible) {
            //playerRef.current.seekTo(0);
            setPitchSecondsPlayed(0);
        }
    }, [playerRef, visible]);

    const setThumbnailFunction = useCallback(async()=>{
        const params = {
            level,
            download: false,
            expires: 1800
        }
        if(identityId) params.identityId = identityId;
        const thumbnailUrl = await Storage.get(mediaUri + ".png", params);
        setThumbnailUrl(thumbnailUrl);
    },[identityId, level, mediaUri]);

    const setMediaFunction = useCallback(async()=>{
        logger.debug("Setting media URL");
        const params = {
            level,
            download: false,
            expires: 1800
        }
        if(identityId) params.identityId = identityId;
        const mediaUrl = await Storage.get(mediaUri + ".mp4", params);
        setMediaUrl(mediaUrl);
        setIsMediaReady(true);
    },[identityId, level, mediaUri]);

    const checkVideoFunction = useCallback(async()=>{
        const params = {
            level,
            download: false,
            expires: 1800
        }
        if(identityId) params.identityId = identityId;

        const results = await Storage.list(mediaUri + ".mp4", params);
        if(results.length > 0){
            logger.debug("Media found");
            setMediaFunction();
        }
    },[identityId, level, mediaUri, setMediaFunction]);

    const {count0} = useInterval(async() => {
        if(encodedAvailable){
            logger.debug("Media encoded already available");
            await setMediaFunction();
        }else{
            logger.debug("Checking for encoded media");
            await checkVideoFunction();
        }
    }, (!isMediaReady && mediaUri && !isTimedOut) ? 30000 : null, 0);

    const {count1} = useInterval(async() => {
        if(encodedAvailable){
            logger.debug("Thumbnail encoding already available");
        }else{
            logger.debug("Trying to reset thumbnail url");
        }
        setThumbnailFunction(mediaUri);
    }, (!thumbnailUrl && mediaUri && !isTimedOut) ? 5000 : null, 1);

    useEffect(() => {
        logger.debug("About to check for thumbnail, times: " + count1);
        logger.debug("About to check for encoded media, times: " + count0);
        if(count0 >= 10) setIsTimedOut(true);
        if(count1 >= 10) setIsTimedOut(true);
    },[count0, count1]);

    useEffect(() => {
        setIsLoading(true);
        setIsMediaReady(false);
        setMediaUrl(null);
        if(!notStartedPlaceholder && !deletedPlaceholder){
            if(!level) throw new Error("Level is mandatory option");
            if((level === 'protected' || level === 'private') && !identityId) throw new Error("Identity id must be specified for level: " + level);
            if(mediaUri) setThumbnailFunction();
            if(encodedAvailable){
                logger.debug("Media encoded already available");
                if(mediaUri) setMediaFunction();
            }else{
                logger.debug("Checking for encoded media");
                if(mediaUri) {
                    setMediaUrl(null);
                    checkVideoFunction();
                }
            }
            setIsLoading(false);
        }
    }, [mediaUri, level, identityId, checkVideoFunction, encodedAvailable, setMediaFunction, setThumbnailFunction, notStartedPlaceholder, deletedPlaceholder]);

    function renderPlayer(){
        return(
            <VideoPlayer
                className="mx-auto"
                ref={playerRef}
                url={mediaUrl}
                light={showThumbnailInternal ? thumbnailUrl : null}
                autoPlay={autoPlay}
                playing={playing}
                visible={visible}
                controls={false}
                loop={false}
                onStart={() => {
                    if(logView) pitchView.startView({[`pitch_started_${viewType}`]: 1});
                    if(onStarted) onStarted();
                }}
                onEnded={() => {
                    if(logView && pitchView.view) {
                        pitchView.incrementMetrics({[`pitch_completed_${viewType}`]: 1});
                        pitchView.endView();
                    }
                    if(onEnded) onEnded();
                }}
                onProgressTuple1={
                    {
                        progressInterval: 5000,
                        onProgress: (progress) => {
                            if(logView && pitchView.view) {
                                if(Math.round(progress.playedSeconds - pitchSecondsPlayed) > 0){
                                    let timeIncrement = Math.round(progress.playedSeconds - pitchSecondsPlayed)
                                    pitchView.incrementMetrics({[`pitch_secondsPlayed_${viewType}`]: timeIncrement});
                                }
                                setPitchSecondsPlayed(progress.playedSeconds);
                            }
                            if(onProgressInFrequent) onProgressInFrequent(progress);//1 argument - metrics object
                        }
                    }
                }
                onProgressTuple2={
                    {
                        progressInterval: 50,
                        onProgress: onProgressFrequent
                    }
                }
                onClickPreview={onClickPreview} //1 argument - click event
                onError={onErrorHandler}
                onSeek={onSeek}
                originalHeight={originalHeight}
                originalWidth={originalWidth}
            />
        );
    }

    function renderThumbnail(text){
        return(
            <ResponsiveImage 
                url={thumbnailUrl}
                onError={() => setThumbnailUrl(null)} 
                containerClassName={"mx-auto"}
                className={`${blur ? "blur": ""}`}
                originalHeight={originalHeight} 
                originalWidth={originalWidth}
                visible={visible}
                overlay={!text ? null : (
                    <Col className="p-0"><h5 className="container-grey-1">{text}</h5>
                    <Spinner animation="border" variant="primary" /></Col>
                )}/>
        );
    }

    function renderPlaceholder(text, isLoading){
        return(
            <Container style={{width: '100%', height: 400 + 'px', position: 'relative'}} className="container-grey-1">
                <Row style={{width: '100%', height: '100%', position: 'absolute', top: '0'}} className="text-center align-items-center p-5">
                    <Col><h5 className="container-grey-1">{text}</h5>
                    {isLoading && (<Spinner animation="border" variant="complement" />)}</Col>
                </Row>
            </Container>
        )
    }

    return(
        <Container className="p-0 m-0">
            {deletedPlaceholder ? (
                renderPlaceholder("Candidate has deleted their account", false)
            ) : !mediaUri || notStartedPlaceholder ? (
                renderPlaceholder("Upload your pitch", false)
            ) : !isLoading &&
                    (!thumbnailUrl && !mediaUrl) ? 
                        renderPlaceholder(placeholderText, true) 
                        :
                        (thumbnailUrl && (!mediaUrl || blur)) ? 
                            renderThumbnail(!mediaUrl ? placeholderText : null) 
                            :
                            renderPlayer()
            }
        </Container>
    );
}