import React from "react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {getYupSchema, required} from "../../libs/shared/schemas";
import { deleteTemporaryFile, uploadTemporaryFile } from "../../api/file_api";
import { getFilePreview } from "../../api/lambda_api";
import { useUser } from "../../libs/hooksLib";
import ColumnSelectFieldGroup from "../forms/fields/ColumnSelectFieldGroup";
import SwitchField from "../forms/fields/SwitchField";
import FileLandingArea from "./FileLandingArea";
import PreviewTable from "./PreviewTable";

function generateHeadersFromRecord(record){
    const headers = [];
    Object.keys(record).forEach(field => headers.push(field));
    return headers;
}

const DataFileUploader = React.forwardRef(({promptText = null, columnSelectors = null, columnSelectorSchema = null, handleSubmit, fileSizeLimit = null}, columnSelectorFormRef) => {

    const {user} = useUser();
    const [hasHeaders, setHasHeaders] = useState(true);
    const [filename, setFilename] = useState();
    const [records, setRecords] = useState();

    const uploadFunction = async(file, progressFnc) => {
        const fname = await uploadTemporaryFile(file, progressFnc);
        setFilename(fname);
        return fname;
    }

    const deleteFunction = async(filename) => {
        await deleteTemporaryFile(filename);
        setRecords(null);
    }

    useEffect(() => {
        async function loadRecords(){
            const recs = await getFilePreview("private/" + user.identityId + "/" + filename, 5, hasHeaders);
            setRecords(recs);
        }
        if(filename) loadRecords();
    }, [filename, hasHeaders, user.identityId]);

    useEffect(() => {
        if(columnSelectorFormRef.current) columnSelectorFormRef.current.resetForm();
    }, [columnSelectorFormRef, columnSelectors, hasHeaders])

    function onSubmit(data){
        handleSubmit(data, filename, hasHeaders);
    }

    function renderColumnSelectForm(columns, options){
        const initialValues = columns.reduce((result, current) => {
            result[current.replace(/\s+/g, '')] = '';
            return result;
        }, {});
        const schema = columns.reduce((result, current) => {
            result[current.replace(/\s+/g, '')] = required;
            return result;
        }, {});
        const selectOptions = options.map(value => ({label: value, value}));

        return (
            <Formik
                validationSchema={columnSelectorSchema ? columnSelectorSchema : getYupSchema(schema)}
                innerRef={columnSelectorFormRef}
                onSubmit={onSubmit}
                initialValues={initialValues}
                >
                {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isSubmitting,
                }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    {columns.map((col, index) => (
                        <ColumnSelectFieldGroup
                            key={index}
                            id={col.replace(/\s+/g, '')}
                            value={values[col.replace(/\s+/g, '')]}
                            label={col}
                            onBlur={handleBlur}
                            touched={touched[col.replace(/\s+/g, '')]}
                            error={errors[col.replace(/\s+/g, '')]}
                            options={selectOptions}
                        />
                    ))}
                </Form>
                )}
            </Formik>
        )
    }

    return(
        <Container>
            {promptText && (<Row className="mb-2">
                <Col>{promptText}</Col>
            </Row>)}
            <Row className="mt-3">
                <Col>
                    <SwitchField 
                        id="headerSwitch"
                        checked={hasHeaders}
                        label={<>First row contains headers</>}
                        handleChange={() => setHasHeaders(prev => !prev)}
                        disabled={false}
                        />
                </Col>
            </Row>
            <FileLandingArea uploadFnc={uploadFunction} deleteFnc={deleteFunction} buttonUploadText="Upload file" buttonDeleteText="Delete file" fileSizeLimit={fileSizeLimit}/>
            {columnSelectors && records && (
                renderColumnSelectForm(columnSelectors, hasHeaders ? generateHeadersFromRecord(records[0]) : [...Array(records[0].length).keys()])
            )}
            {records && records.length > 0 && (
                <PreviewTable 
                    title="File preview"
                    headers={hasHeaders ? generateHeadersFromRecord(records[0]) : [...Array(records[0].length).keys()]}
                    records={records}
                    />
            )}
        </Container>
    );
});

export default DataFileUploader;