import { useRef, useState } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import PitchButton from "../../buttons/PitchButton";

export default function InputWithCopy({text}){

    const [isCopying, setIsCopying] = useState(false);
    const [hasCopied, setHasCopied] = useState(false);
    const target = useRef(null);

    function copyToClipboard(e) {
        setIsCopying(true);
        target.current.select();
        document.execCommand('copy');
        e.target.focus();
        setIsCopying(false);
        setHasCopied(true);
    };

    return(
        <>
            <Row>
                <Col xs={9}>
                    <Form.Control ref={target} type="text" placeholder={text} value={text} readOnly />
                </Col>
                <Col xs={3}>
                    <PitchButton block isLoading={isCopying} onClick={copyToClipboard} variant="complement">Copy</PitchButton>
                </Col>
            </Row>
            {hasCopied ? (
                <Row className="mt-3">
                    <Col xs={12}>
                        <Alert className="mb-0" variant="primary">Copied to clipboard</Alert>
                    </Col>
                </Row>
            ) : null}
        </>
    )
}